/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Nervös die erste Nachricht zu schreiben",
      "Jemand löst das Match auf",
      "Ghosting (und wann es ok ist)",
      "Burnout vom Dating",
      "Wie man das erste Date angeht",
      "Mikroaggression & Fetischisierung",
      "Ich bekomme keine Matches",
      "Leute schreiben mir nie zuerst",
      "Sicherheit bei echtem Treffen",
      "Beleidigende Nachrichten melden",
      "Catfishing erkennen & überwinden",
      "Ich bin unsicher, wo ich anfangen soll",
      "Was passiert, wenn ich etwas melde",
      "Echte Treffen",
      "Zurückweisung",
      "Schädliches Verhalten",
      "Seelische Erschöpfung",
      "Romance Scams",
      "Bumble downloaden",
      "Alle akzeptieren",
      "Wir verwenden Cookies, um unsere Seite zu optimieren. Dazu gehören analytische und Werbe-Cookies. Weitere Informationen findest du in unserer <a p0>Cookie-Richtlinie</a>.",
      "Optionen verwalten",
      "Wie sieht's mit Cookies aus?",
      "Cookie-Richtlinie",
      "Zurück",
      "Telefonnummer fehlerhaft",
      "© {{current_year}} Bumble | Alle Rechte vorbehalten",
      "Meine personenbezogenen Daten nicht verkaufen oder weitergeben",
      "Karriere",
      "Freundschaft",
      "So geht's",
      "Liebe",
      "Story einreichen",
      "Wellness",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Karriere",
      "Unternehmen",
      "Events",
      "Triff neue Leute und lass dich inspirieren – jetzt ist deine Zeit!",
      "Folge Bumble!",
      "Richtlinien",
      "Hilfebereich",
      "Cookies verwalten",
      "Datenschutz",
      "Die App",
      "AGB",
      "Blog",
      "Deine Sicherheit steht immer an erster Stelle.",
      "Sicherheits-Handbuch lesen",
      "Bumble-Safety Center",
      "Ratgeber",
      "Das Sicherheit & Wohlbefinden Center ist ein sich ständig weiterentwickelndes Ressourcenzentrum innerhalb der Bumble App, das unserer Community dabei helfen soll, sichere und gesunde Dating-Erfahrungen zu machen.",
      "Hier kannst du dich über Sicherheitsfeatures informieren, Tools und Ressourcen finden, die dir helfen, sicher zu daten, sowie eine direkte Kommunikationsmöglichkeit mit uns haben.",
      "Öffne die Bumble App",
      "Wähle Sicherheit & Wohlbefinden",
      "Tippe auf das Profil Symbol",
      "So besuchst du das Sicherheit & Wohlbefinden Center",
      "Wenn es sich um einen Notfall handelt, rufe bitte deine örtliche Notrufnummer an.",
      "Hilfe benötigt?",
      "Brauchst du Hilfe?",
      "Nimm dir eine Pause von Bumble, wann immer du sie brauchst. Wir speichern deine bestehenden Matches.",
      "Snooze",
      "Sei dir sicher, dass die Person, mit der du sprichst, die ist, die sie vorgibt zu sein.",
      "Fotoverifizierung",
      "Wenn du dich bei jemandem unsicher oder unwohl fühlst, melde es bitte und wir werden die Situation mit Vorsicht behandeln.",
      "Blockieren und Melden",
      "Wenn dein Match ein freizügiges Foto schickt, wird es automatisch unkenntlich gemacht. Du kannst wählen, ob du es dir ansehen willst.",
      "Warnsystem",
      "Wir haben eine Reihe von Sicherheitsfunktionen entwickelt, die dir helfen, dich während deiner Partnersuche sicher zu fühlen.",
      "Sicherheitsfeatures",
      "Wir haben das Sicherheit & Wohlbefinden Center innerhalb der Bumble App als Ressourcenzentrum entwickelt, um dir zu helfen, sichere und gesunde Dating-Erfahrungen zu machen.",
      "Deine Sicherheit steht immer an erster Stelle.",
      "Willkommen im Sicherheit & Wohlbefinden Center von Bumble",
      "Wir verwenden Cookies. Wenn du deine Einstellungen nicht änderst, erklärst du dich damit einverstanden.",
      "Jetzt App downloaden",
      "Zum Android-Download",
      "Zum iOS-Download",
      "Wir konnten die gesuchte Seite leider nicht finden.",
      "Einloggen",
      "Mitmachen",
      "Sei dabei!",
      "Sorry, da ist was schiefgegangen!"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "Tage",
                  "Tage",
                  "Tagen",
                  "Tage"
            ],
            "singular": [
                  "Tag",
                  "Tags",
                  "Tag",
                  "Tag"
            ]
      },
      "hour": {
            "plural": [
                  "Stunden",
                  "Stunden",
                  "Stunden",
                  "Stunden"
            ],
            "singular": [
                  "Stunde",
                  "Stunde",
                  "Stunde",
                  "Stunde"
            ]
      },
      "minute": {
            "plural": [
                  "Minuten",
                  "Minuten",
                  "Minuten",
                  "Minuten"
            ],
            "singular": [
                  "Minute",
                  "Minute",
                  "Minute",
                  "Minute"
            ]
      },
      "month": {
            "plural": [
                  "Monate",
                  "Monate",
                  "Monaten",
                  "Monate"
            ],
            "singular": [
                  "Monat",
                  "Monats",
                  "Monat",
                  "Monat"
            ]
      },
      "second": {
            "plural": [
                  "Sekunden",
                  "Sekunden",
                  "Sekunden",
                  "Sekunden"
            ],
            "singular": [
                  "Sekunde",
                  "Sekunde",
                  "Sekunde",
                  "Sekunde"
            ]
      },
      "year": {
            "plural": [
                  "Jahre",
                  "Jahre",
                  "Jahren",
                  "Jahre"
            ],
            "singular": [
                  "Jahr",
                  "Jahres",
                  "Jahr",
                  "Jahr"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
