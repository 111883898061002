/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      {
            "__cfg": {
                  "lexem_id": 30151045,
                  "flex_vars_types": {
                        "__gender_me": "Gender"
                  },
                  "values": {
                        "Default": "Scrivere il primo messaggio mi causa ansia",
                        "__gender_me=M": "Scrivere per primo mi causa ansia",
                        "__gender_me=F": "Scrivere per prima mi causa ansia"
                  }
            }
      },
      "Essere eliminati dai collegamenti",
      "Ghosting (quando va bene e quando no)",
      "Cercare l'anima gemella mi ha estenuato",
      "Consigli per il primo appuntamento",
      "Microaggressioni e feticismo",
      "Non ho creato alcun collegamento",
      "Gli altri non mi scrivono per primi",
      "Conoscersi di persona in sicurezza",
      "Segnalare messaggi offensivi",
      "Scovare le false identità e superare le brutte esperienze",
      "Non so da dove cominciare",
      "Cosa succede dopo una segnalazione",
      "Bumble e vita reale",
      "Sentirsi rifiutati",
      "Comportamenti nocivi",
      "Stanchezza mentale",
      "Truffe sentimentali",
      "Scarica Bumble",
      "Accetta tutti",
      "I cookie, tra cui quelli analitici e pubblicitari, ci servono a far funzionare meglio il sito. Leggi la nostra <a p0>Normativa sui cookie</a> per saperne di più.",
      "Gestisci opzioni",
      "Acconsenti all'utilizzo dei cookie?",
      "Normativa sui cookie",
      "Indietro",
      "Numero di telefono errato",
      "© {{current_year}} Bumble | Tutti i diritti sono riservati",
      "Non vendere né condividere le mie informazioni personali",
      "Carriera",
      "Amicizia",
      "Consigli",
      "Amore",
      "Lasciaci la tua storia",
      "Benessere",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble",
      "Lavora con noi",
      "Chi siamo",
      "Eventi",
      "Il momento è QUESTO per conoscere tanta gente interessante.",
      "Segui Bumble",
      "Linee Guida",
      "Assistenza",
      "Preferenze cookie",
      "Informativa sulla privacy",
      "La app",
      "Termini & Condizioni",
      "Blog",
      "La tua sicurezza viene sempre prima di tutto.",
      "Leggi il manuale sulla sicurezza",
      "Centro sicurezza di Bumble",
      "Guide utili",
      "Il centro sicurezza e benessere è una risorsa in continua evoluzione presente nell'app di Bumble; è pensato per aiutare l'intera community a fare incontri sicuri e vivere un'esperienza piacevole.",
      "Ti offre aggiornamenti sulle funzionalità di sicurezza e strumenti per conoscere gente nuova in tutta tranquillità, oltre a un canale di comunicazione diretta con noi.",
      "Accedi all'app di Bumble",
      "Seleziona \"Sicurezza e benessere\"",
      "Tocca l'icona del profilo",
      "Per visitare il centro sicurezza e benessere:",
      "Chiama subito il numero di emergenza locale in caso di emergenza.",
      "Serve una mano?",
      "Ti serve aiuto?",
      "Prenditi una pausa da Bumble quando ne senti il bisogno. Tutti i tuoi collegamenti verranno mantenuti.",
      "Modalità Snooze",
      "Per sapere che la persona con cui parli è chi dice di essere.",
      "Verifica con foto",
      "Se un utente ti mette a disagio o non ti fa sentire al sicuro, segnalacelo. Risolveremo noi la situazione con la dovuta attenzione.",
      "Blocca e segnala",
      "Offuschiamo automaticamente le foto dai contenuti espliciti. Sta a te scegliere se vuoi vederle o meno.",
      "Rilevatore",
      "Abbiamo creato delle funzioni di sicurezza per aiutarti a conoscere gente nuova in tutta tranquillità.",
      "Funzioni di sicurezza",
      "Abbiamo creato il centro sicurezza e benessere nell'app di Bumble per offrire delle risorse che aiutino l'intera community a fare incontri sicuri e vivere un'esperienza piacevole.",
      "La tua sicurezza viene sempre prima di tutto.",
      "Ti diamo il benvenuto al centro sicurezza e benessere di Bumble",
      "Questo sito fa uso di cookie. Continuando, acconsenti al loro utilizzo.",
      "Scarica la App",
      "Scarica l'app per Android",
      "Scarica l'app per iOS",
      "Siamo spiacenti, ma la pagina che cerchi non è stata trovata.",
      "Accedi",
      "Invia",
      "Che fai, non vieni?",
      "Ops… qualcosa è andato storto"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "giorni"
            ],
            "singular": [
                  "giorno"
            ]
      },
      "hour": {
            "plural": [
                  "ore"
            ],
            "singular": [
                  "ora"
            ]
      },
      "minute": {
            "plural": [
                  "minuti"
            ],
            "singular": [
                  "minuto"
            ]
      },
      "month": {
            "plural": [
                  "mesi"
            ],
            "singular": [
                  "mese"
            ]
      },
      "second": {
            "plural": [
                  "secondi"
            ],
            "singular": [
                  "secondo"
            ]
      },
      "year": {
            "plural": [
                  "anni"
            ],
            "singular": [
                  "anno"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
