/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Ik vind het eng om de eerste stap te zetten",
      "Als iemand jou ontmatcht",
      "Ghosting (en wanneer dat oké is)",
      "Burn-out van het daten",
      "Zo overleef je je eerste date",
      "Microagressie en seksuele fetisjes",
      "Ik krijg geen matches",
      "Niemand opent een gesprek met mij",
      "Veilige ontmoetingen",
      "Hatelijke berichten melden",
      "Catfishing herkennen en omgaan met de gevolgen",
      "Waar moet ik beginnen?",
      "Wat gebeurt er als ik iemand meld?",
      "Van Bumble naar ontmoeting",
      "Gevoelens van afwijzing",
      "Schadelijk gedrag",
      "Mentale uitputting",
      "Romance scams",
      "Downloaden",
      "Cookies accepteren",
      "Met cookies werkt onze site beter. We maken gebruik van analytische cookies en reclamecookies. Lees voor meer informatie ons <a p0>Cookiebeleid</a>.",
      "Cookie-instellingen",
      "Geef je toestemming voor cookies",
      "Cookiebeleid",
      "Annuleren",
      "Onjuist telefoonnummer",
      "© {{current_year}} Bumble | Alle rechten voorbehouden",
      "Mijn persoonsgegevens niet verkopen of delen",
      "Carrière",
      "Vriendschap",
      "Tips",
      "Liefde",
      "Vertel jouw verhaal",
      "Wellness",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Carrière",
      "Het bedrijf",
      "Evenementen",
      "Neem het initiatief en leer inspirerende mensen kennen",
      "Volg Bumble",
      "Richtlijnen",
      "Hulp",
      "Cookies beheren",
      "Privacybeleid",
      "De app",
      "Algemene voorwaarden",
      "Blog",
      "Jouw veiligheid staat voorop, altijd.",
      "Veiligheidshandboek bekijken",
      "Bumble Veiligheidscentrum",
      "Advies en hulp",
      "Met ons Centrum voor Veiligheid en Welzijn in de Bumble-app willen we onze leden helpen veilig en gezond te daten. Er verschijnen regelmatig nieuwe artikelen.",
      "Hier vind je informatie over onze veiligheidsfuncties en tips om met meer zelfvertrouwen te daten. Ook kun je hier direct contact met ons opnemen.",
      "Open de Bumble-app",
      "Kies Veiligheid en welzijn",
      "Tik op de profiel-icoon",
      "Zo vind je ons Centrum voor Veiligheid en Welzijn:",
      "Bel in noodgevallen je lokale alarmnummer.",
      "Hulp nodig?",
      "Hulp nodig?",
      "Even genoeg van Bumble? Maak tijd voor jezelf! We bewaren je matches voor je.",
      "Snooze-modus",
      "Nu weet je zeker dat de mensen met wie je chat ook zijn wie ze zeggen.",
      "Fotoverificatie",
      "Als je je door iemands gedrag onveilig of ongemakkelijk voelt, meld het dan. We behandelen iedere melding met zorg.",
      "Blokkeren en melden",
      "Als jouw match een intieme foto stuurt, wordt deze wazig gemaakt. Bepaal zelf of je hem alsnog bekijkt.",
      "Filter intieme foto's",
      "We hebben een compleet pakket veiligheidsfuncties ontwikkeld, zodat je met een gerust hart kunt daten.",
      "Veiligheidsfuncties",
      "Met ons Centrum voor Veiligheid en Welzijn in de Bumble-app willen we onze leden helpen veilig en gezond te daten.",
      "Jouw veiligheid staat voorop, altijd.",
      "Welkom in het Centrum voor Veiligheid en Welzijn van Bumble",
      "We gebruiken cookies. Als je je instellingen niet aanpast, gaan we ervan uit dat je daarmee instemt.",
      "Download de app",
      "Downloaden voor Android",
      "Downloaden voor iOS",
      "Helaas is de pagina die je zoekt niet te vinden",
      "Inloggen",
      "Aanmelden",
      "Doe mee!",
      "Oeps, er ging iets fout"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "dagen"
            ],
            "singular": [
                  "dag"
            ]
      },
      "hour": {
            "plural": [
                  "uur"
            ],
            "singular": [
                  "uur"
            ]
      },
      "minute": {
            "plural": [
                  "minuten"
            ],
            "singular": [
                  "minuut"
            ]
      },
      "month": {
            "plural": [
                  "maanden"
            ],
            "singular": [
                  "maand"
            ]
      },
      "second": {
            "plural": [
                  "seconden"
            ],
            "singular": [
                  "seconde"
            ]
      },
      "year": {
            "plural": [
                  "jaar"
            ],
            "singular": [
                  "jaar"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
