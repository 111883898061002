/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Я боюсь начинать переписку",
      "Отмена лайка",
      "Гостинг (и в каких случаях это нормально)",
      "Усталость от знакомств",
      "Как вести себя на первом свидании",
      "Микроагрессия и фетишизация",
      "У меня нет симпатий",
      "Мне никто не пишет",
      "Подготовка ко встрече в реальной жизни",
      "Жалоба на оскорбительные сообщения",
      "Столкновение с поддельными профилями",
      "Я не знаю, с чего начать",
      "Последствия моей жалобы",
      "Встречи в реальной жизни",
      "Чувство отверженности",
      "Токсичное поведение",
      "Моральное истощение",
      "Мошенничество в сфере онлайн-знакомств",
      "Скачать Bumble",
      "Принять все",
      "Для того, чтобы наш сайт работал лучше, мы используем файлы cookie, в том числе аналитические и рекламные. Ты можешь узнать больше, ознакомившись с нашей <a p0>Политикой использования файлов cookie</a>.",
      "Изменить настройки",
      "Как насчет cookies?",
      "Политика использования файлов cookie",
      "Вернуться",
      "Неверный номер",
      "© Bumble, {{current_year}} | Все права защищены",
      "Не продавать и не передавать мою личную информацию",
      "Карьера",
      "Дружба",
      "Советы",
      "Любовь",
      "Ваша история",
      "Здоровье",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Карьера",
      "Компания",
      "События",
      "Время знакомиться с невероятными людьми!",
      "Подпишитесь на Bumble",
      "Правила",
      "Служба поддержки",
      "Настройки cookie",
      "Конфиденциальность",
      "Приложение",
      "Условия обслуживания",
      "Блог",
      "Ваша безопасность – наш главный приоритет.",
      "Открыть справочник по безопасности",
      "Центр здоровья и безопасности Bumble",
      "Практические советы",
      "«Здоровье и безопасность» – это центр поддержки Bumble. Его цель – помочь вам наслаждаться приятным и безопасным общением онлайн.",
      "Здесь мы рассказываем о мерах безопасности, помогающих защитить вас от неприятных контактов, а также делимся советами о том, как сделать ваше общение максимально комфортным. Если вам необходима помощь, вы всегда можете связаться с нами напрямую.",
      "Откройте приложение Bumble",
      "Выберите «Здоровье и безопасность»",
      "Коснитесь иконки профиля",
      "Чтобы перейти в раздел «Здоровье и безопасность»:",
      "Если вам угрожает опасность, свяжитесь с местной службой помощи в экстренных ситуациях.",
      "Нужна помощь?",
      "Нужна помощь?",
      "Если вы устали от Bumble, вы всегда можете сделать перерыв. Мы сохраним ваши симпатии.",
      "Режим невидимки",
      "Гарантия того, что человек, с которым вы общаетесь, не выдает себя за кого-то другого.",
      "Фотоверификация",
      "Если кто-то в приложении доставляет вам беспокойство или дискомфорт, сообщите об этом нам: остальное мы возьмем на себя.",
      "Заблокировать и пожаловаться",
      "Мы автоматически скрываем изображения откровенного характера. Решение о том, просматривать ли такое фото, всегда остается за вами!",
      "Фотодетектор",
      "Мы разработали целый ряд функций, помогающих сделать ваше общение комфортным и безопасным.",
      "Меры безопасности",
      "«Здоровье и безопасность» – это центр поддержки Bumble. Его цель – помочь вам наслаждаться приятным и безопасным общением онлайн.",
      "Ваша безопасность – наш главный приоритет.",
      "Добро пожаловать в Центр здоровья и безопасности Bumble",
      "Мы используем файлы cookie. Если вы не измените настройки, мы расценим это как ваше согласие.",
      "Скачать приложение",
      "Скачать для Android",
      "Скачать для iOS",
      "Страница не найдена :(",
      "Войти",
      "Присоединиться",
      "Присоединяйтесь!",
      "Кажется, что-то пошло не так"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "few": [
                  "дня",
                  "дней",
                  "дням",
                  "дня",
                  "днями",
                  "днях"
            ],
            "many": [
                  "дней",
                  "дней",
                  "дням",
                  "дней",
                  "днями",
                  "днях"
            ],
            "plural": [
                  "дня",
                  "дней",
                  "дням",
                  "дня",
                  "днями",
                  "днях"
            ],
            "singular": [
                  "день",
                  "дня",
                  "дню",
                  "день",
                  "днём",
                  "дне"
            ],
            "two": [
                  "дня",
                  "дней",
                  "дням",
                  "дня",
                  "днями",
                  "днях"
            ]
      },
      "hour": {
            "few": [
                  "часа",
                  "часов",
                  "часам",
                  "часа",
                  "часами",
                  "часах"
            ],
            "many": [
                  "часов",
                  "часов",
                  "часам",
                  "часов",
                  "часами",
                  "часах"
            ],
            "plural": [
                  "часа",
                  "часов",
                  "часам",
                  "часа",
                  "часами",
                  "часах"
            ],
            "singular": [
                  "час",
                  "часа",
                  "часу",
                  "час",
                  "часом",
                  "часе"
            ],
            "two": [
                  "часа",
                  "часов",
                  "часам",
                  "часа",
                  "часами",
                  "часах"
            ]
      },
      "minute": {
            "few": [
                  "минуты",
                  "минут",
                  "минутам",
                  "минуты",
                  "минутами",
                  "минутах"
            ],
            "many": [
                  "минут",
                  "минут",
                  "минутам",
                  "минут",
                  "минутами",
                  "минутах"
            ],
            "plural": [
                  "минуты",
                  "минут",
                  "минутам",
                  "минуты",
                  "минутами",
                  "минутах"
            ],
            "singular": [
                  "минута",
                  "минуты",
                  "минуте",
                  "минуту",
                  "минутой",
                  "минуте"
            ],
            "two": [
                  "минуты",
                  "минут",
                  "минутам",
                  "минуты",
                  "минутами",
                  "минутах"
            ]
      },
      "month": {
            "few": [
                  "месяца",
                  "месяцев",
                  "месяца",
                  "месяца",
                  "месяца",
                  "месяца"
            ],
            "many": [
                  "месяцев",
                  "месяцев",
                  "месяцев",
                  "месяцев",
                  "месяцев",
                  "месяцев"
            ],
            "plural": [
                  "месяца",
                  "месяцев",
                  "месяца",
                  "месяца",
                  "месяца",
                  "месяца"
            ],
            "singular": [
                  "месяц",
                  "месяца",
                  "месяц",
                  "месяц",
                  "месяц",
                  "месяц"
            ],
            "two": [
                  "месяца",
                  "месяцев",
                  "месяца",
                  "месяца",
                  "месяца",
                  "месяца"
            ]
      },
      "second": {
            "few": [
                  "секунды",
                  "секунд",
                  "секундам",
                  "секунды",
                  "секундами",
                  "секундах"
            ],
            "many": [
                  "секунд",
                  "секунд",
                  "секундам",
                  "секунд",
                  "секундами",
                  "секундах"
            ],
            "plural": [
                  "секунды",
                  "секунд",
                  "секундам",
                  "секунды",
                  "секундами",
                  "секундах"
            ],
            "singular": [
                  "секунда",
                  "секунды",
                  "секунде",
                  "секунду",
                  "секундой",
                  "секунде"
            ],
            "two": [
                  "секунды",
                  "секунд",
                  "секундам",
                  "секунды",
                  "секундами",
                  "секундах"
            ]
      },
      "year": {
            "few": [
                  "года",
                  "лет",
                  "годам",
                  "года",
                  "годами",
                  "годах"
            ],
            "many": [
                  "лет",
                  "лет",
                  "годам",
                  "лет",
                  "годами",
                  "годах"
            ],
            "plural": [
                  "года",
                  "лет",
                  "годам",
                  "года",
                  "годами",
                  "годах"
            ],
            "singular": [
                  "год",
                  "года",
                  "году",
                  "год",
                  "годом",
                  "годе"
            ],
            "two": [
                  "года",
                  "лет",
                  "годам",
                  "года",
                  "годами",
                  "годах"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
