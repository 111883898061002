/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Jeg er nervøs for å skrive først",
      "Å bli av-matchet",
      "Ghosting (og når det er greit)",
      "Jeg opplever dating-utbrenthet",
      "Hvordan navigere første dates",
      "Mikroaggressjon og fetisjering",
      "Jeg får ingen matcher",
      "Folk skriver ikke til meg først",
      "Hvordan være trygg IRL",
      "Rapportere støtende meldinger",
      "Å oppdage og komme over catfishing",
      "Er usikker på hvor jeg skal starte",
      "Hva skjer når jeg rapporterer",
      "Bumble IRL",
      "Følelser av avvisning",
      "Skadelig oppførsel",
      "Mental utmattelse",
      "Kjærlighetssvindel",
      "Få Bumble",
      "Godta alle",
      "Vi bruker cookies for å gjøre nettsiden vår bedre. Dette inkluderer cookies for analyseverktøy og cookies for markedsføring. Sjekk <a p0>Cookies-reglementet</a> for mer informasjon.",
      "Administrer valg",
      "Godkjenn bruken av cookies",
      "Cookies-reglement",
      "Gå tilbake",
      "Feil telefonnummer",
      "© {{current_year}} Bumble | Alle rettigheter forbeholdes",
      "Ikke selg eller del min personlige informasjon",
      "Karriere",
      "Vennskap",
      "Tips",
      "Kjærlighet",
      "Send inn en historie",
      "Helse/velvære",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Jobbmuligheter",
      "Firmaet",
      "Eventer",
      "Tiden er inne for å treffe nye, spennende mennesker.",
      "Følg Bumble",
      "Retningslinjer",
      "Hjelpesenter",
      "Administrer cookies",
      "Personvern",
      "Appen",
      "Vilkår & Betingelser",
      "The Buzz",
      "Din trygghet kommer først, alltid.",
      "Se vår sikkerhetshåndbok",
      "Bumble Safety Center",
      "Guider",
      "Plattformen for trygghet & mental helse er et ressurssenter på Bumble-appen som er designet for å gi tips og råd til medlemmene for en trygg datingopplevelse.",
      "Her kan du holde deg oppdatert på sikkerhetsfunksjoner, finne verktøy og ressurser for hvordan føle deg trygg og sikker på dates, og kommunisere direkte med oss.",
      "Åpne Bumble-appen",
      "Velg trygghet & mental helse",
      "Klikk på profil-ikonet",
      "Besøk plattformen for trygghet & mental helse",
      "Kontakt lokale nødetater dersom det oppstår en nødsituasjon.",
      "En hjelpende hånd?",
      "Trenger du hjelp?",
      "Ta en pause fra Bumble når som helst. Vi lagrer dine eksisterende matcher.",
      "Slumre",
      "Føl deg trygg på at personen er den de utgir seg for å være.",
      "Bildeverifisering",
      "Du kan rapportere det og stole på at vi håndterer det konfidensielt dersom det oppstår en situasjon hvor du føler deg utrygg.",
      "Blokker og rapporter",
      "Hvis en match sender et potensielt upassende bilde blir det automatisk sensurert ved hjelp av kunstig intelligens. Du kan velge om du vil se det.",
      "Private Detector",
      "Vi har bygget et senter med sikkerhetsfunksjoner for å hjelpe deg å føle deg trygg og sikker når du dater.",
      "Sikkerhetsfunksjoner",
      "Vi lagde plattformen for trygghet & mental helse på Bumble-appen som en et ressurssenter for hvordan du kan få en trygg og sikker datingopplevelse.",
      "Din trygghet kommer først, alltid.",
      "Velkommen til Bumbles plattform for trygghet & mental helse",
      "Denne siden benytter seg av cookies. Du godkjenner cookies med mindre du endrer innstillingene dine.",
      "Last ned appen",
      "Last ned for Android",
      "Last ned for iOS",
      "Vi beklager, vi kunne ikke finne siden du leter etter.",
      "Logg inn",
      "Bli med",
      "Bli med på Bumble!",
      "Oops, det oppsto en feil"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "dager"
            ],
            "singular": [
                  "dag"
            ]
      },
      "hour": {
            "plural": [
                  "timer"
            ],
            "singular": [
                  "time"
            ]
      },
      "minute": {
            "plural": [
                  "minutter"
            ],
            "singular": [
                  "minutt"
            ]
      },
      "month": {
            "plural": [
                  "måneder"
            ],
            "singular": [
                  "måned"
            ]
      },
      "second": {
            "plural": [
                  "sekunder"
            ],
            "singular": [
                  "sekund"
            ]
      },
      "year": {
            "plural": [
                  "år"
            ],
            "singular": [
                  "år"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
