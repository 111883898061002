/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      {
            "__cfg": {
                  "lexem_id": 30151045,
                  "flex_vars_types": {
                        "__gender_me": "Gender"
                  },
                  "values": {
                        "Default": "Écrire en premier me rend anxieux",
                        "__gender_me=F": "Écrire en premier me rend anxieuse"
                  }
            }
      },
      "Quand l'autre supprime le match",
      "La fantomisation (et quand l'utiliser)",
      "Les rencontres me surmènent",
      "Comment gérer les premiers rendez-vous",
      "Micro-agressions et fétichisation",
      "Je n'ai aucun match",
      "Personne ne m'écrit en premier",
      "Rencontres en vrai et sécurité",
      "Signalement de messages abusifs",
      "Fraude : Prévenir et guérir",
      "Je ne sais pas par où commencer",
      "Les suites d'un signalement",
      "De Bumble en vrai",
      "Sentiment de rejet",
      "Préjudice",
      "Épuisement mental",
      "Arnaques sentimentales",
      "Télécharger Bumble",
      "Tout accepter",
      "Nous utilisons des cookies pour assurer le bon fonctionnement de nos services, dont des cookies analytiques et des cookies publicitaires. Pour plus d'informations, consultez notre <a p0>Politique des Cookies</a>.",
      "Gérer les options",
      "Un p'tit cookie ?",
      "Politique des cookies",
      "Retour",
      "Numéro de téléphone incorrect",
      "© {{current_year}} Bumble | Tous droits réservés.",
      "Ne pas vendre ou partager mes données personnelles",
      "Carrière",
      "Amitié",
      "Conseils",
      "Amour",
      "Envoyer un témoignage",
      "Bien-être",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Carrières",
      "La société",
      "Événements",
      "Il n'y a jamais eu de meilleur moment pour faire des rencontres enrichissantes.",
      "Suivre Bumble",
      "Règlement",
      "Aide",
      "Gérer les témoins",
      "Politique de confidentialité",
      "L'appli",
      "Conditions d'utilisation",
      "Le blog",
      "Ta sécurité passe avant tout.",
      "Consulter notre manuel de sécurité",
      "Centre de sécurité Bumble",
      "Guides",
      "Le centre Sécurité et bien-être contient une mine d'informations pour te garantir de faire des rencontres sûres chez Bumble.",
      "Tu trouveras ici des outils te permettant de faire des rencontres en toute confiance, ainsi qu'une ligne de communication pour nous contacter directement.",
      "Ouvre l'appli Bumble",
      "Sélectionne Sécurité et bien-être",
      "Appuie sur l'icône de profil",
      "Pour consulter le centre Sécurité et bien-être",
      "Si cela ne peut pas attendre, appelle ton service d'urgences local.",
      "Besoin d'un coup de main?",
      "Besoin d'aide?",
      "Prends une pause de Bumble lorsque tu en ressens le besoin. Tous tes matchs seront sauvegardés.",
      "Mode Invisible",
      "Assure-toi que la personne à qui tu parles est authentique.",
      "Vérification par photo",
      "Si un autre membre te rend mal à l'aise, n'hésite pas à signaler son profil. Nous nous occuperons du reste.",
      "Bloquer et signaler",
      "Si ton match t'envoie une photo à caractère sexuel, elle sera automatiquement floutée. Tu pourras choisir de la dévoiler ou non.",
      "Détecteur de nudité",
      "Nous avons mis en place des mesures de sécurité pour te permettre de faire des rencontres des plus sûres.",
      "Mesures de sécurité",
      "Le centre Sécurité et bien-être contient une mine d'informations pour te garantir de faire des rencontres sûres chez Bumble.",
      "Ta sécurité passe avant tout.",
      "Centre Sécurité et bien-être de Bumble",
      "Nous utilisons des témoins. Si tu ne modifies pas tes paramètres, tu consens à leur utilisation.",
      "Télécharger l'appli",
      "Télécharger pour Android",
      "Télécharger pour iOS",
      "Nous n'avons pas pu trouver la page que tu cherches.",
      "Connexion",
      "Rejoindre",
      "Rejoins la communauté!",
      "Une erreur est survenue"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "jours"
            ],
            "singular": [
                  "jour"
            ]
      },
      "hour": {
            "plural": [
                  "heures"
            ],
            "singular": [
                  "heure"
            ]
      },
      "minute": {
            "plural": [
                  "minutes"
            ],
            "singular": [
                  "minute"
            ]
      },
      "month": {
            "plural": [
                  "mois"
            ],
            "singular": [
                  "mois"
            ]
      },
      "second": {
            "plural": [
                  "secondes"
            ],
            "singular": [
                  "seconde"
            ]
      },
      "year": {
            "plural": [
                  "ans"
            ],
            "singular": [
                  "an"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
