/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "먼저 메시지를 보내는 게 부담스러워요",
      "상대방이 짝을 해제했어요",
      "연락 두절(그리고 연락을 멈춰도 좋을 때)",
      "데이트에 지쳤어요",
      "첫 데이트 가이드",
      "미세 공격 & 페티시화",
      "아무런 짝이 탄생하지 않아요",
      "사람들이 먼저 메시지를 보내지 않아요",
      "실제 만남을 안전하게 준비하기",
      "모욕적인 메시지 신고하기",
      "사칭 프로필 발견 & 피해 대처 방법",
      "어떻게 시작해야 할지 모르겠어요",
      "신고 이후의 과정은 어떻게 되나요?",
      "Bumble 소통부터 실제 만남까지",
      "거절감",
      "유해한 행동",
      "정신적 탈진",
      "로맨스 스캠",
      "Bumble 다운받기",
      "모두 수락",
      "저희는 사이트를 원활하게 운영하기 위해 분석 쿠키와 광고 쿠키를 포함한 쿠키를 사용합니다. 당사의 <a p0>쿠키 정책</a>에서 더 자세한 정보를 확인하실 수 있습니다.",
      "옵션 관리하기",
      "쿠키 사용에 동의하시나요?",
      "쿠키 정책",
      "돌아가기",
      "올바르지 않은 전화번호",
      "© {{current_year}} Bumble | All Rights Reserved",
      "내 개인정보를 판매하거나 공유하지 않음",
      "커리어",
      "우정",
      "전문가의 팁",
      "사랑",
      "회원님의 이야기",
      "웰빙",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "인재채용",
      "회사",
      "이벤트",
      "영감을 주는 새로운 사람들을 만날 수 있는 최고의 순간입니다.",
      "Bumble을\n팔로우하세요",
      "가이드라인",
      "헬프 데스크",
      "쿠키 관리",
      "개인정보 취급방침",
      "앱",
      "이용약관",
      "블로그",
      "회원님의 안전이 가장 중요합니다.",
      "당사의 안전 핸드북을 확인하세요",
      "Bumble 안전 센터",
      "가이드",
      "안전 & 웰빙 센터는 Bumble 앱 내에서 제공되며 끊임없이 업데이트되는 리소스 허브로 커뮤니티 회원들이 안전하고 건강한 데이팅 경험을 즐길 수 있도록 도와드립니다.",
      "이곳에서 안전 기능에 관한 알아보고, 안전한 데이트에 도움이 되는 도구와 자료를 확인하세요. 저희에게 직접 문의하실 수도 있어요.",
      "Bumble 앱을 여세요",
      "안전 & 웰빙을 선택하세요",
      "프로필 아이콘을 탭하세요",
      "안전 & 웰빙 센터에 방문하려면",
      "긴급 상황이라면 지역 긴급 전화번호로 연락하세요.",
      "도움을 요청하세요.",
      "도움이 필요하세요?",
      "필요할 때면 언제든지 Bumble에서 벗어나 휴식을 취하세요. 회원님의 기존 짝은 그대로 유지됩니다.",
      "스누즈",
      "대화하는 상대방이 진짜 회원이라는 걸 확인하세요.",
      "사진 인증",
      "누군가로 인해 불편하거나 불안한 느낌을 받았다면 저희에게 신고해주세요. 철저히 조사하여 처리하겠습니다.",
      "차단 및 신고하기",
      "회원님의 짝이 전송한 노골적인 사진을 자동으로 블러 처리합니다. 그러한 사진을 확인할지, 결정할 권한은 전적으로 회원님에게 있어요.",
      "사진 필터링",
      "회원님이 더욱 안심하고 소통을 즐기실 수 있도록 다양한 안전 기능이 준비되어 있습니다.",
      "안전 기능",
      "Bumble 앱 내에 구축된 안전 & 웰빙 센터는 리소스 허브의 역할을 하며 커뮤니티 회원들이 안전하고 건강한 소통과 만남을 즐길 수 있도록 도와드립니다.",
      "회원님의 안전이 가장 중요합니다.",
      "Bumble의 안전 & 웰빙 센터에 오신 걸 환영합니다",
      "당사 사이트는 쿠키를 사용합니다. 설정을 변경하지 않는다면 귀하는 당사의 쿠키 사용에 동의하는 것으로 간주됩니다.",
      "앱 다운로드",
      "Android용 다운로드",
      "iOS용 다운로드",
      "죄송합니다. 현재 찾으시는 페이지를 발견할 수 없습니다.",
      "로그인하기",
      "가입하기",
      "함께 즐겨보세요!",
      "앗, 오류가 발생했어요"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "일"
            ],
            "singular": [
                  "일"
            ]
      },
      "hour": {
            "plural": [
                  "시간"
            ],
            "singular": [
                  "시간"
            ]
      },
      "minute": {
            "plural": [
                  "분"
            ],
            "singular": [
                  "분"
            ]
      },
      "month": {
            "plural": [
                  "개월"
            ],
            "singular": [
                  "개월"
            ]
      },
      "second": {
            "plural": [
                  "초"
            ],
            "singular": [
                  "초"
            ]
      },
      "year": {
            "plural": [
                  "년"
            ],
            "singular": [
                  "년"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
