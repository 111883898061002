/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "自分からメッセージを送ることに不安を感じる",
      "マッチング解消された",
      "突然連絡がつかなくなる（上手くいっていたのに）",
      "交際に疲れてしまった",
      "初デートの進め方",
      "マイクロアグレッションとフェチ化",
      "まったくマッチしない",
      "先にメッセージを送ってもらえない",
      "安全に会うために準備する",
      "攻撃的なメッセージを報告する",
      "なりすましを見抜く＆その被害に対処する",
      "どこから始めればいいか分からない",
      "報告したらどうなるか",
      "Bumbleから初デートへ",
      "拒否​された​と​感じたら",
      "悪質な行為に気づいたら",
      "精神的に疲れた時は",
      "ロマンス詐欺",
      "Bumbleをゲット",
      "全て承認する",
      "サイトのより円滑な運営のためにクッキーを使用しており、分析のためのクッキーと広告のためのクッキーがそれに含まれます。詳しくは<a p0>クッキーポリシー</a>をご確認ください。",
      "オプションを管理",
      "クッキーに同意していただけますか？",
      "クッキーポリシー",
      "戻る",
      "電話番号が間違っています",
      "©{{current_year}}Bumble | 不許複製・禁無断転載",
      "個人情報を販売または共有しない",
      "キャリア",
      "友情",
      "使い方",
      "Love",
      "エピソードを投稿",
      "ウェルネス",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "採用情報",
      "会社",
      "イベント",
      "活気あふれる人たちと新しく出会うには、今が最高の時です。",
      "Bumbleをフォローする",
      "ガイドライン",
      "ヘルプセンター",
      "Cookieの管理",
      "プライバシーポリシー",
      "アプリ",
      "利用規約",
      "ブログ",
      "いつでも、あなたの安全が第一です。",
      "安全のためのハンドブックを確認する",
      "Bumbleセーフティセンター",
      "ご利用ガイド",
      "安全＆健康センターは、コミュニティーに参加する人たちが安全で健全なデートをすることを手助けするために作られた、Bumbleアプリ内の進化する情報の中心地です。",
      "こちらから、安全に関する機能についての情報を入手したり、自信をもって交際するためのツールや方法を見つけたり、弊社への直通電話をかけることが可能です。",
      "Bumbleアプリを開く",
      "「安全＆健康」を選ぶ",
      "プロフィールアイコンをタップする",
      "安全＆健康センターの使い方",
      "緊急事態の場合は、お住いの地域の緊急電話番号におかけください。",
      "お手伝いが必要ですか？",
      "お手伝いが必要ですか？",
      "必要な時はいつでも、Bumbleを休むことが出来ます。既存のマッチはそのまま保存されます。",
      "スヌーズモード",
      "会話をしている相手が、プロフィール通りの人物であることを知る。",
      "写真認証",
      "もし誰かに対して危険や居心地の悪さを感じたら、ご報告ください。慎重に誠意をもって対応いたします。",
      "ブロック・通報",
      "もしマッチした相手から露骨な写真が送られてきた場合、その写真は自動的にぼかしをかけられるので、見るかどうか選ぶことができます。",
      "Private Detector",
      "あなたが安心して交際できるように、一そろいの安全機能をご用意しました。",
      "安全機能",
      "安全で健全なデートをすることを手助けするための情報源となるよう、Bumbleアプリ内に安全＆健康センターを設けています。",
      "いつでも、あなたの安全が第一です。",
      "Bumbleの安全＆健康センターへようこそ",
      "弊社はクッキーを使用しています。設定の変更が行われなかった場合、あなたはその使用に同意したとみなされます。",
      "アプリをダウンロード",
      "Android版をダウンロード",
      "iOS版をダウンロード",
      "申し訳ありません、お探しのページが見つかりません。",
      "ログイン",
      "参加",
      "仲間に入ろう！",
      "問題が発生しました"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "日"
            ],
            "singular": [
                  "日"
            ]
      },
      "hour": {
            "plural": [
                  "時間"
            ],
            "singular": [
                  "時間"
            ]
      },
      "minute": {
            "plural": [
                  "分"
            ],
            "singular": [
                  "分"
            ]
      },
      "month": {
            "plural": [
                  "ヶ月"
            ],
            "singular": [
                  "ヶ月"
            ]
      },
      "second": {
            "plural": [
                  "秒"
            ],
            "singular": [
                  "秒"
            ]
      },
      "year": {
            "plural": [
                  "年"
            ],
            "singular": [
                  "年"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
