/* eslint-disable */
export default {
      "profile@safety_center.articles.anxious_about_messaging_first.title": 0,
      "profile@safety_center.articles.being_unmatched.title": 1,
      "profile@safety_center.articles.ghosting.title": 2,
      "profile@safety_center.articles.got_dating_burnout.title": 3,
      "profile@safety_center.articles.how_to_navigate_first_dates.title": 4,
      "profile@safety_center.articles.microaggressions_fetishization.title": 5,
      "profile@safety_center.articles.not_getting_any_matches.title": 6,
      "profile@safety_center.articles.people_dont_message_me_first.title": 7,
      "profile@safety_center.articles.preparing_for_safety_irl.title": 8,
      "profile@safety_center.articles.reporting_abusive_messages.title": 9,
      "profile@safety_center.articles.spotting_recovering_catfish.title": 10,
      "profile@safety_center.articles.unsure_where_to_start.title": 11,
      "profile@safety_center.articles.what_happens_when_i_report.title": 12,
      "profile@safety_center.cards.bumble_to_irl.title": 13,
      "profile@safety_center.cards.feelings_of_rejection.title": 14,
      "profile@safety_center.cards.harmful_behaviour.title": 15,
      "profile@safety_center.cards.mental_exhaustion.title": 16,
      "profile@safety_center_guides_harmful_behaviour_article_6_title": 17,
      "share@NATIVE_SHARING_MW_LANDING_PAGE/ACTION": 18,
      "settings@cookies.banner.accept_cta": 19,
      "settings@cookies.banner.body": 20,
      "settings@cookies.banner.settings_cta": 21,
      "settings@cookies.banner.title": 22,
      "settings@cookies.footer.cookie_policy": 23,
      "onboarding@bumble.incognito.dismiss_warning.cta.cancel": 24,
      "signin@PHONE_INCORRECT": 25,
      "corp@copyright_notice": 26,
      "bumblesite@ccpa_cta.title": 27,
      "bumblesite@LAYOUT.FOOTER.BEEHIVE.CAREER": 28,
      "bumblesite@LAYOUT.FOOTER.BEEHIVE.FRIENDSHIP": 29,
      "bumblesite@LAYOUT.FOOTER.BEEHIVE.HOW_TO": 30,
      "bumblesite@LAYOUT.FOOTER.BEEHIVE.LOVE": 31,
      "bumblesite@LAYOUT.FOOTER.BEEHIVE.SUBMIT_STORY": 32,
      "bumblesite@LAYOUT.FOOTER.BEEHIVE.WELLNESS": 33,
      "bumblesite@LAYOUT.FOOTER.BUMBLE_BFF": 34,
      "bumblesite@LAYOUT.FOOTER.BUMBLE_BIZZ": 35,
      "bumblesite@LAYOUT.FOOTER.BUMBLE_DATE": 36,
      "bumblesite@LAYOUT.FOOTER.CAREERS": 37,
      "bumblesite@LAYOUT.FOOTER.COMPANY": 38,
      "bumblesite@LAYOUT.FOOTER.EVENTS": 39,
      "bumblesite@LAYOUT.FOOTER.FOLLOW.CAPTION": 40,
      "bumblesite@LAYOUT.FOOTER.FOLLOW.TITLE": 41,
      "bumblesite@LAYOUT.FOOTER.GUIDELINES": 42,
      "bumblesite@LAYOUT.FOOTER.HELP_CENTER": 43,
      "bumblesite@LAYOUT.FOOTER.MANAGE_COOKIES": 44,
      "bumblesite@LAYOUT.FOOTER.PRIVACY_POLICY": 45,
      "bumblesite@LAYOUT.FOOTER.THEAPP": 46,
      "bumblesite@LAYOUT.FOOTER.TNC": 47,
      "bumblesite@LAYOUT.HEADER.BEEHIVE": 48,
      "bumblesite@LAYOUT.HERO_BLOCK.TITLE": 49,
      "bumblesite@safety.landing.safetybook.cta": 50,
      "bumblesite@SAFETY_CENTER.BROWSER_TAB": 51,
      "bumblesite@SAFETY_CENTER.HOW_TO.TITLE": 52,
      "bumblesite@SAFETY_CENTER.INTRO.PART.ONE": 53,
      "bumblesite@SAFETY_CENTER.INTRO.PART.TWO": 54,
      "bumblesite@SAFETY_CENTER.INTRO.STEP.ONE": 55,
      "bumblesite@SAFETY_CENTER.INTRO.STEP.THREE": 56,
      "bumblesite@SAFETY_CENTER.INTRO.STEP.TWO": 57,
      "bumblesite@SAFETY_CENTER.INTRO.TITLE": 58,
      "bumblesite@SAFETY_CENTER.NEED_HELP.CALL.TITLE": 59,
      "bumblesite@SAFETY_CENTER.NEED_HELP.HELP_CENTER.TITLE": 60,
      "bumblesite@SAFETY_CENTER.NEED_HELP.TITLE": 61,
      "bumblesite@SAFETY_CENTER.SAFETY_FEATURES.CARDS.FOUR.TEXT": 62,
      "bumblesite@SAFETY_CENTER.SAFETY_FEATURES.CARDS.FOUR.TITLE": 63,
      "bumblesite@SAFETY_CENTER.SAFETY_FEATURES.CARDS.ONE.TEXT": 64,
      "bumblesite@SAFETY_CENTER.SAFETY_FEATURES.CARDS.ONE.TITLE": 65,
      "bumblesite@SAFETY_CENTER.SAFETY_FEATURES.CARDS.THREE.TEXT": 66,
      "bumblesite@SAFETY_CENTER.SAFETY_FEATURES.CARDS.THREE.TITLE": 67,
      "bumblesite@SAFETY_CENTER.SAFETY_FEATURES.CARDS.TWO.TEXT": 68,
      "bumblesite@SAFETY_CENTER.SAFETY_FEATURES.CARDS.TWO.TITLE": 69,
      "bumblesite@SAFETY_CENTER.SAFETY_FEATURES.DESCRIPTION": 70,
      "bumblesite@SAFETY_CENTER.SAFETY_FEATURES.TITLE": 71,
      "bumblesite@SAFETY_CENTER.SOCIAL_SHARING.DESCRIPTION": 72,
      "bumblesite@SAFETY_CENTER.SOCIAL_SHARING.TITLE": 73,
      "bumblesite@SAFETY_CENTER.VIDEO.TITLE": 74,
      "bma_client@cookie-notification.text": 75,
      "bma_client@dialog.photo_verification.not_access.download_app": 76,
      "bma_client@download_app_android": 77,
      "bma_client@download_app_ios": 78,
      "bma_client@pages.error.not-found.description": 79,
      "bma_client@pages.login-password.submit": 80,
      "bma_client@pages.registration.form.submit": 81,
      "bma_client@pages.registration.form.title": 82,
      "bma_client@video_chat.error_title": 83
} as const;
