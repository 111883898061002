/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Me da ansiedad hablar yo primero",
      "Me dieron desconectar",
      "Ghosting (y cuándo se vale)",
      "Ya me cansé del dating",
      "Cómo manejar las primeras citas",
      "Microagresiones y fetichismo",
      "No consigo conectar",
      "Nadie me habla primero",
      "Conocerse en persona de forma segura",
      "Reportar mensajes ofensivos",
      "Descubrir y recuperarse de un catfish",
      "No sé por dónde empezar",
      "¿Qué sucede cuando reporto?",
      "De Bumble a la vida real",
      "Sentimiento de rechazo",
      "Comportamiento nocivo",
      "Agotamiento mental",
      "Estafas amorosas",
      "Descargar Bumble",
      "Aceptar todas",
      "Usamos cookies para que nuestro sitio funcione mejor. Esto incluye cookies de análisis de datos y cookies de anuncios. Para más información, revisá nuestra <a p0>Política de Cookies</a>.",
      "Administrar opciones",
      "Indicanos si aceptás las cookies",
      "Política de Cookies",
      "Atrás",
      "Número de celular incorrecto",
      "@{{current_year}}Bumble | Todos los derechos reservados",
      "No vender o compartir mi información personal",
      "Trayectoria profesional",
      "Amistad",
      "Consejos",
      "Amor",
      "Enviar história",
      "Bienestar",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Empleo",
      "Quiénes somos",
      "Eventos",
      "Siempre es un buen momento para conocer gente que nos ayude a ser mejores.",
      "Seguir a Bumble",
      "Reglas",
      "Centro de ayuda",
      "Gestionar cookies",
      "Política de privacidad",
      "La aplicación",
      "Condiciones de Uso",
      "Blog",
      "Tu seguridad es primero, siempre.",
      "Consultar nuestro Manual de seguridad",
      "Centro de seguridad de Bumble",
      "Consejos",
      "El Centro de Seguridad y Bienestar es una fuente de recursos dentro de la app de Bumble, creado para ayudar a que nuestra comunidad tenga una experiencia sana y segura.",
      "Acá podés encontrar información sobre nuestras medidas de seguridad, herramientas y recursos para darte confianza en tus citas y un canal directo de comunicación para contactarnos.",
      "Abre la app de Bumble",
      "Seleccioná Seguridad y Bienestar",
      "Pulsa sobre el icono del perfil",
      "Para visitar el Centro de Seguridad y Bienestar",
      "Si se trata de una emergencia, por favor llama a tu número de emergencias local.",
      "¿Necesitas ayuda?",
      "¿Necesitas ayuda?",
      "Tómate un descanso de Bumble cuando lo necesites. Te guardaremos las conexiones que ya tengas para cuando vuelvas.",
      "No molestar",
      "Para que sepas con seguridad que la persona con quien hablas, es quien dice ser.",
      "Verificación por foto",
      "Si alguien te hace sentir inseguridad o incomodidad, por favor repórtalo y nos haremos cargo.",
      "Bloquear y Reportar",
      "Si tu conexión te manda fotos explícitas, aparecerán borrosas de manera automática. Tú decides si quieres verlas o no.",
      "Detector Privado",
      "Hemos creado varias herramientas de seguridad, diseñadas para protegerte durante todo momento.",
      "Medidas de seguridad",
      "Hemos creado el Centro de Seguridad y Bienestar dentro de la app de Bumble como una fuente de recursos para ayudar a que nuestra comunidad tenga una experiencia sana y segura.",
      "Tu seguridad es primero, siempre.",
      "Te damos la bienvenida al Centro de Seguridad y Bienestar de Bumble",
      "Esta página utiliza cookies. Si no deseas ajustar tu configuración, asumiremos que estás de acuerdo con el uso de las cookies.",
      "Descargar la aplicación",
      "Descargar para Android",
      "Descargar para iOS",
      "Lo sentimos, pero no pudimos encontrar la página que estabas buscando.",
      "Entrar",
      "Únete",
      "¡Únete a la Colmena!",
      "Parece que algo falló..."
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "días"
            ],
            "singular": [
                  "día"
            ]
      },
      "hour": {
            "plural": [
                  "horas"
            ],
            "singular": [
                  "hora"
            ]
      },
      "minute": {
            "plural": [
                  "minutos"
            ],
            "singular": [
                  "minuto"
            ]
      },
      "month": {
            "plural": [
                  "meses"
            ],
            "singular": [
                  "mes"
            ]
      },
      "second": {
            "plural": [
                  "segundos"
            ],
            "singular": [
                  "segundo"
            ]
      },
      "year": {
            "plural": [
                  "años"
            ],
            "singular": [
                  "año"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
