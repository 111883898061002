/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "I’m anxious about messaging first",
      "Being unmatched",
      "Ghosting (and when it’s ok)",
      "I’ve got dating burnout",
      "How to navigate first dates",
      "Microaggressions & fetishization",
      "I’m not getting any matches",
      "People don’t message me first",
      "Preparing for safety IRL",
      "Reporting abusive messages",
      "Spotting & recovering from catfishing",
      "I’m unsure where to start",
      "What happens when I report",
      "Bumble to IRL",
      "Feelings of rejection",
      "Harmful behavior",
      "Mental exhaustion",
      "Romance Scams",
      "Get Bumble",
      "Accept All",
      "We use cookies to make our site work better. This includes analytics cookies and advertising cookies. For more information, please check our <a p0>Cookie Policy</a>.",
      "Manage Options",
      "Let us know you agree to cookies",
      "Cookie Policy",
      "Go back",
      "Incorrect phone number",
      "© {{current_year}} Bumble | All Rights Reserved",
      "Do not sell or share my personal information",
      "Career",
      "Friendship",
      "How To",
      "Love",
      "Submit a story",
      "Wellness",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Careers",
      "The company",
      "Events",
      "There’s never been a better moment to meet new, empowering people.",
      "Follow Bumble",
      "Guidelines",
      "Help Center",
      "Manage Cookies",
      "Privacy Policy",
      "The app",
      "Terms & Conditions",
      "The Buzz",
      "Your safety comes first, always.",
      "Review our Safety Handbook",
      "Bumble Safety Center",
      "How to guides",
      "The Safety & Wellbeing center is an evolving resource hub within the Bumble app built to help our community have a safe and healthy dating experience.",
      "Here, you can stay informed on safety features, find tools and resources to help you date confidently, as well as have a direct line of communication with us.",
      "Open the Bumble app",
      "Select Safety & Wellbeing",
      "Tap the profile icon",
      "To visit the Safety & Wellbeing center",
      "If it’s an emergency, please call your local emergency number.",
      "Need a hand?",
      "Need help?",
      "Take a break from Bumble whenever you need it. We’ll save your existing matches.",
      "Snooze",
      "Know the person you’re talking to is who they say they are.",
      "Photo Verification",
      "If anyone makes you feel unsafe or uncomfortable, please report it and we’ll handle the situation with care.",
      "Block and Report",
      "If your match sends an explicit photo, it’ll automatically be blurred out. You get to choose if you want to look.",
      "Private Detector",
      "We’ve built a suite of safety features designed to help keep you feeling secure throughout your dating journey.",
      "Safety features",
      "We built The Safety & Wellbeing center within the Bumble app as a resource hub to help you have a safe and healthy dating experience.",
      "Your safety comes first, always.",
      "Welcome to Bumble’s Safety & Wellbeing Center",
      "We are using cookies. If you do not adjust your settings we assume you are ok with this.",
      "Download the App",
      "Download for Android",
      "Download for iOS",
      "We’re sorry, but we can’t find the page you were looking for.",
      "Sign In",
      "Join",
      "Join the Hive!",
      "Oops, something went wrong"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "days"
            ],
            "singular": [
                  "day"
            ]
      },
      "hour": {
            "plural": [
                  "hours"
            ],
            "singular": [
                  "hour"
            ]
      },
      "minute": {
            "plural": [
                  "minutes"
            ],
            "singular": [
                  "minute"
            ]
      },
      "month": {
            "plural": [
                  "months"
            ],
            "singular": [
                  "month"
            ]
      },
      "second": {
            "plural": [
                  "seconds"
            ],
            "singular": [
                  "second"
            ]
      },
      "year": {
            "plural": [
                  "years"
            ],
            "singular": [
                  "year"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
