/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "主动发信息会让我感到不安",
      "被取消配对",
      "突然消失（没有任何征兆）",
      "我已经厌倦约会",
      "如何首次约会",
      "微侵略和恋物癖",
      "我基本上没有配对",
      "对方不主动给我发信息",
      "为安全的现实约会做好准备",
      "举报辱骂性信息",
      "发现并克服网络自夸",
      "我不知道从何开始",
      "当我举报时会发生什么",
      "从Bumble到现实约会",
      "被拒绝时的情绪",
      "有害行为",
      "精神疲劳",
      "交友垃圾信息",
      "下载Bumble",
      "全部接受",
      "我们使用cookie来让我们的网站更好地运营。这包括分析cookie和广告cookie。欲了解更多信息，请查阅我们的<a p0>Cookie政策</a>。",
      "管理选项",
      "您同意使用cookie",
      "Cookie政策",
      "返回",
      "手机号码不正确",
      "© {{current_year}} Bumble | 保留所有权利",
      "不允许售卖或​分享​我的​个人​信息",
      "事业",
      "友谊",
      "如何使用",
      "爱情",
      "提交您的故事",
      "健康",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "工作机会",
      "公司",
      "活动",
      "这种结交有力的新朋友的方式是前所未有的。",
      "关注Bumble",
      "指导原则",
      "帮助中心",
      "管理Cookies",
      "隐私声明",
      "应用",
      "条款",
      "嗡嗡",
      "您的安全总是第一位的。",
      "查看《安全手册》",
      "Bumble安全中心",
      "指导原则",
      "安全与健康中心是Bumble应用程序内不断发展的资源中心，旨在帮助我们的社群获得安全和健康的约会体验。",
      "在这里，您可以随时了解安全功能、查找工具和资源，以让您放心地约会，并可以与我们直接取得联系。",
      "打开Bumble应用程序",
      "选择安全与健康",
      "点击“个人档案”图标",
      "要访问安全与健康中心",
      "如果您遇到紧急情况，请拨打当地的紧急热线。",
      "需要帮助吗？",
      "需要帮助？",
      "您可以随时选择暂时离开Bumble。现有的配对都会为您保留。",
      "瞌睡模式",
      "确认与您聊天的人的信息都是真实的。",
      "照片认证",
      "如果对方让您感到不安全或不舒服，您可以举报，我们会谨慎处理。",
      "阻止并举报",
      "如果您的配对向您发送不雅照片，系统会自动进行模糊处理。您可以自行选择是否查看。",
      "私人探测器",
      "我们设计了一系列安全功能，旨在为您的约会之旅保驾护航。",
      "安全功能",
      "我们在Bumble应用程序内建立了安全与健康中心，这是一个资源区，旨在帮助您获得安全和健康的约会体验。",
      "您的安全总是第一位的。",
      "欢迎来到Bumble的安全与健康中心",
      "我们使用cookies。保留当前设置表示您同意这一点。",
      "下载应用",
      "Android下载",
      "iOS下载",
      "对不起，无法找到该网页。",
      "登录",
      "加入",
      "一起加入吧！",
      "啊，发生了一个错误"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "天"
            ],
            "singular": [
                  "天"
            ]
      },
      "hour": {
            "plural": [
                  "小时"
            ],
            "singular": [
                  "小时"
            ]
      },
      "minute": {
            "plural": [
                  "分钟"
            ],
            "singular": [
                  "分钟"
            ]
      },
      "month": {
            "plural": [
                  "月"
            ],
            "singular": [
                  "月"
            ]
      },
      "second": {
            "plural": [
                  "秒"
            ],
            "singular": [
                  "秒"
            ]
      },
      "year": {
            "plural": [
                  "年"
            ],
            "singular": [
                  "年"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
