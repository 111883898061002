/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "主動發訊息會感到不安",
      "被取消配對",
      "突然消失(沒有任何徵兆)",
      "我已厭倦約會",
      "如何首次約會",
      "微侵略&戀物癖",
      "沒有配對",
      "對方不主動給我發訊息",
      "準備安全約會",
      "檢舉辱罵訊息",
      "發現並阻止“照騙”",
      "不知道從何開始",
      "檢舉後會怎樣",
      "Bumble進入現實約會",
      "被拒的感受",
      "有害行為",
      "精神疲勞",
      "愛情詐騙",
      "下載Bumble",
      "接受全部",
      "我們使用cookie更好的運營網站，包括cookie分析和cookie廣告。更多訊息，請查看 <a p0>Cookie政策</a>。",
      "管理選項",
      "您同意使用cookies",
      "Cookie政策",
      "返回",
      "電話號碼不正確",
      "(c) {{current_year}} Bumble | 保留所有版權",
      "不許售賣或分享我的個人信息",
      "事業",
      "友誼",
      "如何使用",
      "愛情",
      "提交您的故事",
      "健康",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "工作機會",
      "公司",
      "活動",
      "這種結交有力的新朋友的方式是前所未有的。",
      "關注Bumble",
      "指導原則",
      "使用說明",
      "管理 Cookie",
      "隱私權政策",
      "應用",
      "條款",
      "嗡嗡",
      "我們始終重視您的安全",
      "查看我們的安全手冊",
      "Bumble安全中心",
      "指導原則",
      "安全與健康中心是Bumble APP資源區，旨在維護社群約會的安全與健康。",
      "在這裡，您可以隨時瞭解安全功能、查找工具和資源，以便放心約會。您還可以直接與我們聯繫。",
      "打開Bumble app",
      "選擇安全與健康",
      "點擊個人資料圖標",
      "前往安全與健康中心",
      "如果是緊急情況，請撥打當地的緊急熱線。",
      "需要幫助嗎？",
      "需要幫助？",
      "您可以隨時選擇短暫離開Bumble，現有的配對會保留。",
      "睡眠模式",
      "確認與您聊天的是本人",
      "相片驗證",
      "如果您感到不安全或不舒服，請檢舉，我們會謹慎處理。",
      "封鎖並檢舉",
      "如果配對傳送不雅相片，系統會自動打碼。您可以自行選擇是否查看",
      "Private Detector",
      "我們有一系列的安全功能，旨在為您的約會之旅，保駕護航。",
      "安全功能",
      "安全與健康中心是Bumble APP資源區，旨在維護社群約會的安全與健康。",
      "我們始終重視您的安全",
      "歡迎來到Bumble安全與健康中心",
      "我們使用cookies。保留當前設定表示您同意這一點。",
      "下載應用",
      "Android下載",
      "iOS下載",
      "對不起，無法找到該網頁。",
      "登入",
      "加入",
      "一起加入吧！",
      "糟糕，出錯啦"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "天"
            ],
            "singular": [
                  "天"
            ]
      },
      "hour": {
            "plural": [
                  "小時"
            ],
            "singular": [
                  "小時"
            ]
      },
      "minute": {
            "plural": [
                  "分鐘"
            ],
            "singular": [
                  "分鐘"
            ]
      },
      "month": {
            "plural": [
                  "月"
            ],
            "singular": [
                  "月"
            ]
      },
      "second": {
            "plural": [
                  "秒"
            ],
            "singular": [
                  "秒"
            ]
      },
      "year": {
            "plural": [
                  "年"
            ],
            "singular": [
                  "年"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
