/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      {
            "__cfg": {
                  "lexem_id": 30151045,
                  "flex_vars_types": {
                        "__gender_me": "Gender"
                  },
                  "values": {
                        "Default": "J'ai de l'anxiété à l'idée d'envoyer le premier message",
                        "__gender_me=M": "Je suis anxieux à l'idée d'envoyer le premier message",
                        "__gender_me=F": "Je suis anxieuse à l'idée d'envoyer le premier message"
                  }
            }
      },
      "Quand l'autre supprime le Match",
      "Le ghosting (et quand y avoir recours)",
      "Je fais un burnout amoureux",
      "Comment gérer les premiers dates",
      "Micro-agressions et fétichisation",
      "Je n'ai aucun Match",
      "Les gens ne m'envoient pas le premier message",
      "Rencontres en personne et sécurité",
      "Signalement de messages abusifs",
      "Repérer une fausse identité et se remettre de ses abus",
      "Je ne sais pas trop par où commencer",
      "Ce qui se passe lorsque je fais un signalement",
      "De Bumble à la vraie vie",
      "Sentiment de rejet",
      "Attitude nuisible",
      "Épuisement mental",
      "Arnaques sentimentales",
      "Télécharger Bumble",
      "Tout accepter",
      "Nous utilisons des cookies pour assurer le bon fonctionnement de nos services, dont des cookies analytiques et des cookies publicitaires. Pour plus d'informations, consultez notre <a p0>Politique des Cookies</a>.",
      "Gérer les options",
      "Un p'tit cookie ?",
      "Politique des cookies",
      "Retour",
      "Numéro de téléphone incorrect",
      "© {{current_year}} Bumble | Tous droits réservés.",
      "Ne pas vendre ou partager mes données personnelles",
      "Carrière",
      "Amitié",
      "Conseils",
      "Amour",
      "Envoyer un témoignage",
      "Bien-être",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Emploi",
      "La société",
      "Événements",
      "Il n'y a jamais eu de meilleur moment pour faire des rencontres dignes de ce nom.",
      "Suivre Bumble",
      "Charte",
      "Aide",
      "Gérer les cookies",
      "Confidentialité",
      "L'appli",
      "Conditions",
      "Le Blog",
      "Ta sécurité passe avant tout.",
      "Consulter notre manuel de sécurité",
      "Centre de sécurité Bumble",
      "Guides",
      "Le centre Sécurité et bien‑être contient une mine d'informations pour te garantir de faire des rencontres sûres chez Bumble.",
      "Tu trouveras ici des outils te permettant de te lancer dans le dating en toute confiance, et de nous contacter directement.",
      "Ouvre l'appli Bumble",
      "Choisis Sécurité et bien-être",
      "Appuie sur l'icône de profil",
      "Pour consulter le centre Sécurité et bien‑être",
      "Si cela ne peut pas attendre, appelle ton service d'urgences local.",
      "Besoin d'un coup de main ?",
      "Besoin d'aide ?",
      "Si tu as envie de faire une petite pause, le mode Snooze est là pour ça. Nous sauvegarderons tous tes Matchs pour plus tard.",
      "Snooze",
      "Assure‑toi que la personne avec qui tu discutes soit authentique.",
      "Vérification par photo",
      "Si tu te sens mal à l'aise à cause d'un autre membre, n'hésite pas à signaler son profil. Nous nous occuperons du reste.",
      "Bloquer et signaler",
      "Si ton Match t'envoie une photo à caractère sexuel, elle sera automatiquement floutée. Tu pourras choisir de la dévoiler ou pas.",
      "Détecteur",
      "Nous avons mis en place des mesures de sécurité pour te permettre de faire des rencontres des plus sûres.",
      "Mesures de sécurité",
      "Le centre Sécurité et bien‑être contient une mine d'informations pour te garantir de faire des rencontres sûres chez Bumble.",
      "Ta sécurité passe avant tout.",
      "Centre Sécurité et bien-être de Bumble",
      "Nous utilisons des cookies pour recueillir des informations. Il est possible d'en modifier les paramètres.",
      "Télécharger l'appli",
      "Télécharger pour Android",
      "Télécharger pour iOS",
      "Cette page est introuvable.",
      "Se connecter",
      "S'inscrire",
      "Bienvenue !",
      "Une erreur est survenue"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "jours"
            ],
            "singular": [
                  "jour"
            ]
      },
      "hour": {
            "plural": [
                  "heures"
            ],
            "singular": [
                  "heure"
            ]
      },
      "minute": {
            "plural": [
                  "minutes"
            ],
            "singular": [
                  "minute"
            ]
      },
      "month": {
            "plural": [
                  "mois"
            ],
            "singular": [
                  "mois"
            ]
      },
      "second": {
            "plural": [
                  "secondes"
            ],
            "singular": [
                  "seconde"
            ]
      },
      "year": {
            "plural": [
                  "ans"
            ],
            "singular": [
                  "an"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
