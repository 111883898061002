/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Czuję obawę, gdy mam wysłać pierwszą wiadomość",
      "Ktoś usunął dopasowanie ze mną",
      "Ghosting (i kiedy to jest ok)",
      "Odczuwam wypalenie randkowe",
      "Jak przygotować się do pierwszej randki",
      "Mikroagresja i fetyszyzacja",
      "Nie mam żadnych dopasowań",
      "Nikt nie wysyła do mnie wiadomości jako pierwszy",
      "Bezpieczeństwo dot. spotkania na żywo",
      "Zgłoszenia obraźliwych wiadomości",
      "Catfishing – wykrywanie i powrót do normalności",
      "Nie wiem, jak zacząć",
      "Co dzieje się po zgłoszeniu",
      "Od Bumble do spotkania w realu",
      "Uczucia odrzucenia",
      "Szkodliwe zachowanie",
      "Wyczerpanie psychiczne",
      "Romantyczne oszustwa",
      "Pobierz Bumble",
      "Zaakceptuj wszystkie",
      "Korzystamy z plików cookie, aby usprawnić naszą stronę. Korzystamy z cookie analitycznych i reklamowych. Więcej informacji znajdziesz tu: <a p0>Polityka plików cookie</a>.",
      "Zarządzaj opcjami",
      "Powiedz nam czy zgadzasz się na cookie",
      "Korzystanie z plików cookie",
      "Wróć",
      "Nieprawidłowy numer telefonu",
      "© {{current_year}} Bumble | Wszelkie prawa zastrzeżone",
      "Nie sprzedawaj ani nie udostępniaj moich danych osobowych",
      "Kariera",
      "Przyjaźń",
      "Porady",
      "Miłość",
      "Wyślij historię",
      "Samopoczucie",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Praca",
      "Firma",
      "Wydarzenia",
      "To najlepszy moment na poznanie nowych, inspirujących ludzi.",
      "Obserwuj Bumble",
      "Zasady użytkowania",
      "Pomoc",
      "Zarządzaj plikami cookie",
      "Polityka Prywatności",
      "Aplikacja",
      "Regulamin",
      "Blog",
      "Twoje bezpieczeństwo jest najważniejsze, zawsze.",
      "Zapoznaj się naszym Przewodnikiem bezpieczeństwa",
      "Safety Center Bumble",
      "Porady",
      "Centrum bezpieczeństwa i samopoczucia to ciągle aktualizowane zasoby aplikacji Bumble, które mają za zadanie pomóc naszej społeczności bezpiecznie się umawiać.",
      "Tutaj znajdziesz informacje dot. opcji bezpieczeństwa, narzędzia i zasoby, które pomogą Ci w umawianiu się na randki oraz skontaktowaniu się z nami.",
      "Otwórz aplikację Bumble",
      "Wybierz \"Bezpieczeństwo i samopoczucie\"",
      "Kliknij ikonę profilu",
      "Odwiedź \"Centrum bezpieczeństwa i samopoczucia\"",
      "Jeśli potrzebujesz natychmiastowej pomocy, zadzwoń na lokalny numer alarmowy.",
      "Potrzebujesz wsparcia?",
      "Potrzebujesz pomocy?",
      "Zrób sobie przerwę od Bumble kiedykolwiek tylko potrzebujesz. Zachowamy Twoje dotychczasowe dopasowania.",
      "Tryb cichy",
      "Dowiedz się czy osoba, z którą rozmawiasz jest tą, za którą się podaje.",
      "Weryfikacja zdjęciem",
      "Jeśli ktoś sprawi, że poczujesz się zagrożony(-a) lub niekomfortowo, zgłoś tę osobę a my zajmiemy się sytuacją.",
      "Zablokuj i zgłoś",
      "Jeśli dopasowana osoba wyśle śmiałe zdjęcie, zostanie ono automatycznie rozmazane. Od Ciebie zależy czy chcesz je zobaczyć.",
      "Fotodetektor",
      "Mamy szereg funkcji dot. bezpieczeństwa, które są zaprojektowane po to, aby nasza społeczność czuła się bezpiecznie podczas swojej randkowej podróży.",
      "Funkcje bezpieczeństwa",
      "Stworzyliśmy \"Centrum bezpieczeństwa i samopoczucia\" w aplikacji Bumble, które zawiera informacje mające na celu pomóc naszej społeczności bezpiecznie się umawiać.",
      "Twoje bezpieczeństwo jest najważniejsze, zawsze.",
      "Witaj w centrum bezpieczeństwa i samopoczucia Bumble",
      "Używamy plików cookie. Jeśli nie zmienisz swoich ustawień dotyczących tych plików, zakładamy, że je akceptujesz.",
      "Pobierz aplikację",
      "Pobierz na Android",
      "Pobierz na iOS",
      "Przykro nam, ale nie nie możemy odnaleźć strony, której szukasz.",
      "Zaloguj się",
      "Dołącz",
      "Dołącz do nas!",
      "Ojej, coś poszło nie tak"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "few": [
                  "dni",
                  "dni",
                  "dniom",
                  "dni",
                  "dniach",
                  "dniami",
                  "dni"
            ],
            "many": [
                  "dni",
                  "dni",
                  "dniom",
                  "dni",
                  "dniach",
                  "dniami",
                  "dni"
            ],
            "plural": [
                  "dni",
                  "dni",
                  "dni",
                  "dni",
                  "dni",
                  "dni",
                  "dni"
            ],
            "singular": [
                  "dzień",
                  "dnia",
                  "dniowi",
                  "dzień",
                  "dniu",
                  "dniem",
                  "dzień"
            ]
      },
      "hour": {
            "few": [
                  "godziny",
                  "godzin",
                  "godzinom",
                  "godziny",
                  "godzinach",
                  "godzinami",
                  "godziny"
            ],
            "many": [
                  "godzin",
                  "godzin",
                  "godzinom",
                  "godzin",
                  "godzinach",
                  "godzinami",
                  "godzin"
            ],
            "plural": [
                  "godzin",
                  "godzin",
                  "godzin",
                  "godzin",
                  "godzin",
                  "godzin",
                  "godzin"
            ],
            "singular": [
                  "godzina",
                  "godziny",
                  "godzinie",
                  "godzinę",
                  "godzinie",
                  "godziną",
                  "godzina"
            ]
      },
      "minute": {
            "few": [
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min."
            ],
            "many": [
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min."
            ],
            "plural": [
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min."
            ],
            "singular": [
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min."
            ]
      },
      "month": {
            "few": [
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies."
            ],
            "many": [
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies."
            ],
            "plural": [
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies."
            ],
            "singular": [
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies."
            ]
      },
      "second": {
            "few": [
                  "sekundy",
                  "sekund",
                  "sekundom",
                  "sekundy",
                  "sekundach",
                  "sekundami",
                  "sekundy"
            ],
            "many": [
                  "sekund",
                  "sekund",
                  "sekundom",
                  "sekund",
                  "sekundach",
                  "sekundami",
                  "sekund"
            ],
            "plural": [
                  "sekund",
                  "sekund",
                  "sekund",
                  "sekund",
                  "sekund",
                  "sekund",
                  "sekund"
            ],
            "singular": [
                  "sekunda",
                  "sekundy",
                  "sekundzie",
                  "sekundę",
                  "sekundzie",
                  "sekundą",
                  "sekundo"
            ]
      },
      "year": {
            "few": [
                  "lata",
                  "lat",
                  "latom",
                  "lata",
                  "latach",
                  "latami",
                  "lata"
            ],
            "many": [
                  "lat",
                  "lat",
                  "latom",
                  "lat",
                  "latach",
                  "latami",
                  "lat"
            ],
            "plural": [
                  "lat",
                  "lat",
                  "lat",
                  "lat",
                  "lat",
                  "lat",
                  "lat"
            ],
            "singular": [
                  "rok",
                  "roku",
                  "rokowi",
                  "rok",
                  "roku",
                  "rokiem",
                  "rok"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
