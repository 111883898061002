/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      {
            "__cfg": {
                  "lexem_id": 30151045,
                  "flex_vars_types": {
                        "__gender_me": "Gender"
                  },
                  "values": {
                        "Default": "Fico ansioso ao enviar a primeira mensagem",
                        "__gender_me=F": "Fico ansiosa ao enviar a primeira mensagem"
                  }
            }
      },
      "Ter a ligação desfeita",
      "Ghosting (e quando é aceitável)",
      "Tenho um esgotamento em relações",
      "Lidar com primeiros encontros",
      "Micro-agressão e fetichismo",
      "Não consigo ter ligações",
      "Nunca recebo mensagens primeiro",
      "Encontros seguros na vida real",
      "Denúncia de mensagens abusivas",
      "Identificar e recuperar de catfishing",
      "Não sei onde começar",
      "O que acontece quando há uma denúncia",
      "Bumble e vida real",
      "Lidar com rejeição",
      "Mau comportamento",
      "Exaustão mental",
      "Fraudes sentimentais",
      "Instalar o Bumble",
      "Aceitar tudo",
      "Utilizamos cookies para melhorar o funcionamento do site. Isso inclui cookies analíticos e cookies de publicidade. Para mais informações, consulte a nossa <a p0>Política de Cookies</a>",
      "Gerir opções",
      "Diga-nos se concorda com os nossos cookies",
      "Política de Cookies",
      "Voltar",
      "Número de telefone incorreto",
      "© {{current_year}} Bumble | Todos os direitos reservados.",
      "Não vender nem partilhar as minhas informações pessoais",
      "Carreira",
      "Amizade",
      "Conselhos",
      "Amor",
      "Enviar história",
      "Bem-estar",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Vagas",
      "Empresa",
      "Eventos",
      "Nunca houve um momento melhor para conhecer pessoas novas e inspiradoras.",
      "Seguir Bumble",
      "Regras",
      "Centro de Ajuda",
      "Gerir Cookies",
      "Política de Privacidade",
      "App",
      "Termos e Condições",
      "O Buzz",
      "A tua segurança vem primeiro, sempre.",
      "Rever Manual de Segurança",
      "Safety Centre do Bumble",
      "Guias",
      "O centro de Segurança e bem-estar é um recurso do Bumble em evolução, desenvolvido para ajudar a comunidade a ter relacionamentos seguros e saudáveis.",
      "Aqui, podes informar-te sobre os recursos de segurança e encontrar ferramentas, além de teres uma linha direta de comunicação connosco.",
      "Abre a app do Bumble",
      "Seleciona Segurança e bem-estar",
      "Toca no ícone de perfil",
      "Para visitar o centro de Segurança e bem-estar",
      "Se for uma emergência, por favor liga para o teu número local de emergências.",
      "Precisa de ajuda?",
      "Necessitas de ajuda?",
      "Faz um intervalo no Bumble quando precisares. Nós manteremos os teus matches atuais.",
      "Não perturbar",
      "Saber que as pessoas com quem falas são realmente quem dizem ser.",
      "Verificação por foto",
      "Se alguém te causar desconforto ou insegurança, por favor, denuncia e nós trataremos da situação com todo o cuidado.",
      "Bloquear e Denunciar",
      "Se o teu match te enviar uma foto obscena, ela será automaticamente desfocada. Podes escolher vê-la ou não.",
      "Detector Privado",
      "Desenvolvemos uma série de recursos de segurança para te ajudar a manter confiante durante toda a jornada do relacionamento.",
      "Recursos de segurança",
      "Criámos o Centro de Segurança e bem-estar do Bumble para ser uma fonte de apoio para que os utilizadores tenham uma experiência segura e saudável em relacionamentos.",
      "A tua segurança vem primeiro, sempre.",
      "Bem-vindo(a) ao Centro de Segurança e Bem-estar do Bumble",
      "Este site utiliza cookies. Se não alterares as tuas definições, isso significa que concordas com o uso de cookies.",
      "Instalar a App",
      "Instalar para Android",
      "Instalar para iOS",
      "Página não encontrada.",
      "Entrar",
      "Entrar",
      "Junta-te à Colmeia!",
      "Ups, ocorreu um problema"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "dias"
            ],
            "singular": [
                  "dia"
            ]
      },
      "hour": {
            "plural": [
                  "horas"
            ],
            "singular": [
                  "hora"
            ]
      },
      "minute": {
            "plural": [
                  "minutos"
            ],
            "singular": [
                  "minuto"
            ]
      },
      "month": {
            "plural": [
                  "meses"
            ],
            "singular": [
                  "mês"
            ]
      },
      "second": {
            "plural": [
                  "segundos"
            ],
            "singular": [
                  "segundo"
            ]
      },
      "year": {
            "plural": [
                  "anos"
            ],
            "singular": [
                  "ano"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
