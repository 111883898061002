import { Provider as LocaleProvider } from 'lib/locale-context';
import { Provider as GeoProvider } from 'lib/geo-context';
import localization from 'lib/localization';
import useBodyOSClasses from 'lib/use-body-os-classes';
import { Localization } from '@bumble/localization/components';
import { DefaultSeo } from 'next-seo';
import type { AppProps } from 'next/app';
import { Router } from 'next/router';
import React, { useEffect } from 'react';
import supportedLanguageIds, { SupportedLanguage } from 'lib/lexemes/supported-languages';
import { AppError } from 'lib/app-error';
// @ts-ignore
import { ErrorBoundary } from '@bumble/react-error-boundary';
import { logger, useGelatoLogger } from 'lib/use-gelato-logger';
import '../styles/base.scss';
import { FetchedLanguageId } from '@bumble/localization/types';

function App({ Component, router, pageProps }: AppProps) {
    // @ts-ignore
    const lang = getDefaultLanguage(pageProps.lang);
    const lexemes = getLang(lang);
    // @ts-ignore
    const country = pageProps.geoIpCountry;

    useBodyOSClasses();

    useLazyLoadCss();

    useGelatoLogger();

    return (
        <ErrorBoundary
            debug={false}
            logger={(error, errorDetails) => {
                if (process.env.NODE_ENV === 'production') {
                    logger.trackError(error, {
                        ...errorDetails,
                        origin: 'ErrorBoundary',
                        debugInfo: errorDetails?.debug_info,
                    });
                }
            }}
        >
            <GeoProvider value={country}>
                <LocaleProvider value={lang}>
                    <Localization
                        instance={localization}
                        lang={lang as FetchedLanguageId}
                        entries={lexemes.lexemes}
                        commonWords={lexemes.common}
                    >
                        <DefaultSeo
                            languageAlternates={getLanguageAlternates(router)}
                            canonical={getCanonical(router)}
                            facebook={{
                                appId: '428250913904849',
                            }}
                            additionalMetaTags={[
                                {
                                    name: 'apple-itunes-app',
                                    content: 'app-id=930441707',
                                },
                                {
                                    property: 'p:domain_verify',
                                    content: '26cd87d4b7db08a05bdfa2219d3671b6',
                                },
                                {
                                    property: 'referrer',
                                    content: 'origin-when-cross-origin',
                                },
                                {
                                    property: 'twitter:app:name:iphone',
                                    content: 'Bumble - Changing the rules of the game.',
                                },
                                {
                                    property: 'twitter:app:id:iphone',
                                    content: '930441707',
                                },
                            ]}
                            openGraph={{
                                type: 'website',
                                locale: lang,
                                url: getCanonical(router),
                                site_name: 'Bumble Buzz',
                            }}
                            twitter={{
                                handle: '@bumble',
                                cardType: 'summary_large_image',
                                site: '@bumble',
                            }}
                        />
                        <Component {...pageProps} />
                    </Localization>
                </LocaleProvider>
            </GeoProvider>
        </ErrorBoundary>
    );
}

export default App;

function getDefaultLanguage(lang: string): SupportedLanguage {
    if (supportedLanguageIds.includes(lang as SupportedLanguage)) {
        return lang as SupportedLanguage;
    } else if (typeof lang === 'string') {
        // We have a language being passed, but it's not valid, it means user is on an invalid url
        // e.g. /xx/the-buzz or team.bumble.com/xx/open-roles
        throw new AppError({
            statusCode: 404,
            message: `Invalid page language - ${lang}`,
        });
    }

    return 'en';
}

function getLang(lang: SupportedLanguage) {
    return require(`lib/lexemes/${lang}`).default;
}

/**
 * Returns "alternate" languages for SEO
 */
function getLanguageAlternates(_router: Router) {
    return [];
}

function getCanonical(_router: Router) {
    return '';
}

/**
 * Uses "Google approved" way of lazy loading CSS
 * 'deferred-styles' is a noscript tag embedded in the page which the browser doesn't execute for normal environments
 * We just read it and re-add it to the body
 */
function useLazyLoadCss() {
    useEffect(() => {
        const addStylesNode = global.document.getElementById('deferred-styles');
        if (!addStylesNode) {
            return;
        }

        const linkEl = global.document.createElement('link');
        linkEl.rel = 'stylesheet';
        linkEl.href = addStylesNode.getAttribute('href') || '';
        global.document.body.appendChild(linkEl);
    }, []);
}
