/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Keskustelun aloittaminen tuntuu vaikealta",
      "Kadonneet yhteydet",
      "Ghoustaus (joskus ihan ok)",
      "Koen deittiväsymystä",
      "Vinkkejä ensitapaamiseen",
      "Mikroagressiot & fetisointi",
      "En onnistu luomaan yhteyksiä",
      "Kukaan ei aloita keskustelua",
      "Turvallisuus ensitapaamisella",
      "Häirintäviesteistä ilmoittaminen",
      "Suojautuminen nettihuijauksilta",
      "En tiedä, mistä aloittaisin",
      "Miten ilmoitukset käsitellään",
      "Bumblesta tosielämään",
      "Hylätyksi tulemisen pelko",
      "Häirintä",
      "Ylirasitus",
      "Romanssihuijaukset",
      "Hanki Bumble",
      "Hyväksy kaikki",
      "Käytämme evästeitä sivuston toiminnan edistämiseksi. Evästeet sisältävät analytiikka- ja mainosevästeitä. Lisätietoa saa lukemalla <a p0>evästekäytäntömme</a>.",
      "Hallitse valintoja",
      "Vahvista, että hyväksyt evästeet",
      "Evästekäytäntö",
      "Mene takaisin",
      "Virheellinen puhelinnumero",
      "© {{current_year}} Bumble | Kaikki oikeudet pidätetään.",
      "Älä myy tai jaa henkilökohtaisia tietojani",
      "Ura",
      "Ystävyys",
      "Miten tehdä",
      "Rakkaus",
      "Jaa tarinasi",
      "Hyvinvointi",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Työpaikat",
      "Yritys",
      "Tapahtumat",
      "Mikä ainutlaatuinen tilaisuus tavata uusia, hyvän itsetunnon omaavia ihmisiä.",
      "Seuraa Bumblea",
      "Käyttöohjeet",
      "Tukipalvelu",
      "Hallinnoi evästeitä",
      "Tietosuoja",
      "Appi",
      "Käyttöehdot",
      "Buzz",
      "Turvallisuutesi on aina etusijalla.",
      "Tutustu turvallisuuskäsikirjaamme",
      "Bumblen turvallisuuskeskus",
      "Miten-oppaat",
      "Turvallisuus- ja hyvinvointikeskus sisältä ajankohtaista tietoa, jonka tarkoituksena on huolehtia hyvinvoinnistasi Bumblea käyttäessäsi.",
      "Täällä saat ajankohtaista tietoa turvallisuusominaisuuksista sekä resursseja ja työkaluja, jotka antavat varmuutta deittailuun. Voit myös pitää meihin yhteyttä tätä kautta.",
      "Käynnistä Bumble-sovellus",
      "Valitse turvallisuus & hyvinvointi",
      "Napsauta profiilikuvaketta",
      "Turvallisuus- ja hyvinvointikeskus sinulle",
      "Kiireellisissä hätätilanteissa soita paikalliseen hätänumeroon.",
      "Avun tarpeessa?",
      "Tarvitko apua?",
      "Pidä pieni tauko tarvittaessa ilman, että menettäisit yhteytesi Bumblessa.",
      "Poissaolotila",
      "Varmista, että keskustelukumppanisi todella on, kuka hän sanoo olevansa.",
      "Kuvavahvistus",
      "Jos joku tapahtuma saa sinut tuntemaan huolta tai pelkoa, ilmoita meille asiasta, niin voimme ryhtyä tarvittaviin toimenpiteisiin.",
      "Estä ja ilmoita",
      "Tunnistamme mahdolliset häirintäkuvat ja varoitamme sinua ennen niiden avaamista.",
      "Privaattisuodatin",
      "Olemme luoneet turvallisuusominaisuuksia, joiden tarkoituksena on auttaa sinua tuntemaan olosi turvalliseksi deittailun joka vaiheessa.",
      "Turvallisuusominaisuudet",
      "Turvallisuus- ja hyvinvointikeskuksen tarkoituksena on huolehtia hyvinvoinnistasi Bumblea käyttäessäsi.",
      "Turvallisuutesi on aina etusijalla.",
      "Tervetuloa Bumblen turvallisuus- ja hyvinvointikeskukseen",
      "Tämä sivusto käyttää evästeitä. Jos et muuta asetuksia oletamme, että hyväksyt evästeiden käytön.",
      "Lataa sovellus",
      "Lataa Android-appi",
      "Lataa iOS-appi",
      "Valitettavasti etsimääsi sivua ei löytynyt.",
      "Kirjaudu sisään",
      "Liity",
      "Liity pesään!",
      "No voi, jotain on vialla"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää"
            ],
            "singular": [
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä"
            ]
      },
      "hour": {
            "plural": [
                  "tuntia",
                  "tuntia",
                  "tunnin",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia"
            ],
            "singular": [
                  "tunti",
                  "tunti",
                  "tunnin",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti"
            ]
      },
      "minute": {
            "plural": [
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia"
            ],
            "singular": [
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti"
            ]
      },
      "month": {
            "plural": [
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta"
            ],
            "singular": [
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi"
            ]
      },
      "second": {
            "plural": [
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia"
            ],
            "singular": [
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti"
            ]
      },
      "year": {
            "plural": [
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta"
            ],
            "singular": [
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
