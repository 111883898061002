/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "मैं पहले मैसेज भेजने के बारे में चिंतित हूं",
      "अनमैच किया जाना",
      "गायब हो होना (और जब ये ठीक हो)",
      "मुझे डेटिंग ने परेशान कर दिया है",
      "पहली डेट्स को कैसे नेविगेट करें",
      "सूक्ष्म आक्रमकता और फेटिशसेशन",
      "मुझे कोई मैच नहीं मिल रहे",
      "लोग मुझे पहले मैसेज नहीं भेजते",
      "असल ज़िन्दगी में सुरक्षा के लिए तैयारी",
      "अपमानजनक संदेशों की रिपोर्ट करना",
      "स्पॉटिंग और नकली प्रोफाइलों से उबरना",
      "मुझे पता नहीं कि कहाँ से शुरू करें",
      "क्या होता है जब मैं रिपोर्ट करता हूं",
      "Bumble से असल जीवन तक",
      "अस्वीकृति की भावनाएं",
      "हानिकारक व्यवहार",
      "मानसिक थकावट",
      "रोमांस स्कैम",
      "Bumble प्राप्त करें",
      "सभी को स्वीकार करें",
      "हम अपनी साइट को बेहतर बनाने के लिए कुकीज़ का उपयोग करते हैं। इसमें एनालिटिक्स कुकीज़ और विज्ञापन कुकीज़ शामिल हैं। अधिक जानकारी के लिए, कृपया हमारी <a p0>कुकी नीति</a> देखें।",
      "विकल्प प्रबंधित करें",
      "हमें बता दें कि आप कुकीज़ से सहमत हैं",
      "कुकी नीति",
      "वापस जाएँ",
      "ग़लत फोन क्रमांक",
      "© {{current_year}} Bumble | सभी अधिकार सुरक्षित हैं",
      "मेरी निजी जानकारी न बेचें या न शेयर करें",
      "कैरियर",
      "दोस्ती",
      "कैसे करें",
      "प्यार",
      "कोई कहानी जमा करें",
      "कल्याण",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble डेट",
      "करियर",
      "कंपनी",
      "इवेंट्स",
      "नये, सशक्त लोगों से मिलने के लिए इससे बेहतर समय कभी भी नहीं आया है।",
      "Bumble को फॉलो करें",
      "दिशानिर्देश",
      "सहायता केंद्र",
      "कुकीज़ प्रबंधित करें",
      "गोपनीयता नीति",
      "ऐप",
      "नियम और शर्तें",
      "बज्ज़",
      "आपकी सुरक्षा सबसे पहले नंबर पर है, हमेशा।",
      "हमारी सुरक्षा हैंडबुक को पढ़ें",
      "Bumble सुरक्षा केंद्र",
      "\"कैसे करें\" गाइडस",
      "Bumble ऐप के अंदर सुरक्षा और कल्याण केंद्र एक उभरता हुआ संसाधन केंद्र है जो एक सुरक्षित और स्वस्थ डेटिंग अनुभव प्रदान करने में मदद करने के लिए हमारी कम्युनिटी के लिए बनाया गया है।",
      "यहां, आपको सुरक्षा फीचर्स, आत्मविश्वास के साथ डेट करने के लिए टूल्स और संसाधनों को खोजने में आपकी मदद, साथ ही हमारे साथ सीधे संचार करने के बारे में सूचित किया जा सकता है।",
      "Bumble ऐप खोलें",
      "सुरक्षा और कल्याण को चुनें",
      "प्रोफ़ाइल आइकॉन पर टैप करें",
      "सुरक्षा और कल्याण केंद्र पर जाने के लिए",
      "यदि यह एक इमरजेंसी है, तो कृपया अपने स्थानीय इमरजेंसी नंबर पर कॉल करें।",
      "सहायता चाहिये?",
      "मदद चाहिये?",
      "जब भी आपको जरूरत हो Bumble से ब्रेक लें। हम आपके मौजूदा मैच सहेज कर रखेंगे।",
      "स्नूज़",
      "जानिये कि आप जिस से बात कर रहे हैं वो वही है जो वो बता रहे हैं।",
      "फ़ोटो वेरिफिकेशन",
      "यदि कोई आपको असुरक्षित या असहज महसूस कराता है, तो कृपया इसकी रिपोर्ट करें और हम स्थिति को सावधानी से संभाल लेंगे।",
      "ब्लॉक व रिपोर्ट करें",
      "यदि आपका मैच एक अश्लील फ़ोटो भेजता है, तो यह स्वचालित रूप से धुंधली हो जाएगी। आप चुन सकते हैं कि क्या आप देखना चाहते हैं या नहीं।",
      "Private Detector",
      "हमने आपकी डेटिंग यात्रा के दौरान आपको सुरक्षित महसूस करने में मदद करने के लिए सुरक्षा फीचर्स का एक श्रृंखला बनाई है।",
      "सुरक्षा फीचर्स",
      "हमने Bumble ऐप के अंदर सुरक्षा और कल्याण केंद्र बनाया जो एक सुरक्षित और स्वस्थ डेटिंग अनुभव पाने में आपकी मदद करने के लिए एक संसाधन केंद्र के रूप में काम करता है।",
      "आपकी सुरक्षा सबसे पहले नंबर पर है, हमेशा।",
      "Bumble के सुरक्षा और कल्याण केंद्र में स्वागत है",
      "हम कुकीज़ का उपयोग कर रहे हैं। यदि आप अपनी सेटिंग्स को समायोजित नहीं करते तो हम मानते हैं कि आप को इस से कोई परेशानी नहीं है।",
      "ऐप डाउनलोड करें",
      "Android के लिए डाउनलोड करें",
      "iOS के लिए डाउनलोड करें",
      "हमें खेद है, पर आप जिस पेज को खोज रहे हैं हम उसे खोज नहीं सके हैं।",
      "साइन इन करें",
      "शामिल हो जाएँ",
      "समुदाय में शामिल हों!",
      "अरे रे! कुछ गड़बड़ हो गई है"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "दिन",
                  "दिनों"
            ],
            "singular": [
                  "दिन",
                  "दिन"
            ]
      },
      "hour": {
            "plural": [
                  "घंटे",
                  "घंटे"
            ],
            "singular": [
                  "घंटे",
                  "घंटे"
            ]
      },
      "minute": {
            "plural": [
                  "मिनट",
                  "मिनटों"
            ],
            "singular": [
                  "मिनट",
                  "मिनट"
            ]
      },
      "month": {
            "plural": [
                  "महीने",
                  "महीनों"
            ],
            "singular": [
                  "महीना",
                  "महीना"
            ]
      },
      "second": {
            "plural": [
                  "सेकंड",
                  "सेकंड"
            ],
            "singular": [
                  "सेकंड",
                  "सेकंड"
            ]
      },
      "year": {
            "plural": [
                  "साल",
                  "सालों"
            ],
            "singular": [
                  "साल",
                  "साल"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
