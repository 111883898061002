/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Ansiedade para enviar a primeira mensagem",
      "Conexões desfeitas",
      "Ghosting (e quando isso é ok)",
      "Cansei da procura por relacionamentos",
      "Como lidar com os primeiros encontros",
      "Microagressão e fetichismo",
      "Não consigo fazer conexões",
      "As pessoas não começam uma conversa comigo",
      "Encontros na vida real de forma segura",
      "Denunciando mensagens abusivas",
      "Como reconhecer e lidar com perfis falsos e golpes",
      "Eu não sei por onde começar",
      "O que acontece quando eu faço uma denúncia",
      "Do Bumble para vida real",
      "Sentimento de rejeição",
      "Comportamento nocivo",
      "Exaustão mental",
      "Golpes do amor",
      "Baixar o Bumble",
      "Aceitar todos",
      "Usamos os cookies para melhorar o funcionamento do nosso site. Isso inclui cookies analíticos e cookies de publicidade. Para mais informações, confira nossa <a p0>Política de Cookies</a>",
      "Gerenciar opções",
      "Confirme que você concorda com os cookies",
      "Política de Cookies",
      "Voltar",
      "Número de telefone incorreto",
      "© {{current_year}} Bumble | Todos os direitos reservados",
      "Não vender nem compartilhar as minhas informações pessoais",
      "Carreira",
      "Amizade",
      "Dicas",
      "Amor",
      "Envie um depoimento",
      "Bem-estar",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Vagas",
      "Empresa",
      "Eventos",
      "Nunca houve um melhor momento para conhecer pessoas novas e inspiradoras.",
      "Siga o Bumble",
      "Regras",
      "Ajuda",
      "Gerenciar Cookies",
      "Política de Privacidade",
      "App",
      "Termos e Condições",
      "O Buzz",
      "Sua segurança em primeiro lugar, sempre.",
      "Consulte o nosso Manual de segurança",
      "Central de Segurança do Bumble",
      "Guia útil",
      "O centro de Segurança e Bem-estar é um recurso em evolução dentro do Bumble desenvolvido para ajudar nossa comunidade a ter experiências de relacionamentos seguras e saudáveis.",
      "Aqui, você pode se informar sobre os recursos de segurança, encontrar ferramentas e formas de como podemos te ajudar a se relacionar com segurança, além de ter uma linha direta de comunicação conosco.",
      "Abra o app do Bumble",
      "Selecione Segurança e Bem-estar",
      "Toque no ícone do perfil",
      "Para visitar o centro de Segurança e Bem-estar",
      "Se for uma emergência, por favor, ligue para o seu número de emergência local.",
      "Precisa de ajuda?",
      "Precisa de ajuda?",
      "Dê um tempo do Bumble quando você precisar. Nós manteremos suas conexões atuais.",
      "Modo Não Perturbe",
      "Saber que as pessoas com quem você está falando são realmente quem eles dizem ser.",
      "Verificação por Foto",
      "Se alguém te causar desconforto ou insegurança, por favor, denuncie e nós cuidaremos dessa situação com muita cautela.",
      "Bloquear e denunciar",
      "Se sua conexão te enviar uma foto obscena. Esta será automaticamente desfocada. Você pode escolher se quer ou não ver.",
      "Detector de Privacidade",
      "Nós desenvolvemos uma série de recursos de segurança feitos para te ajudar a se manter confiante durante toda a jornada do relacionamento.",
      "Recursos de Segurança",
      "Nós desenvolvemos o centro de Segurança e Bem-estar dentro to Bumble como uma fonte de ajuda para que você tenha uma experiência segura e saudável nos relacionamentos.",
      "Sua segurança em primeiro lugar, sempre.",
      "Conheça o Centro de Segurança e Bem‑estar do Bumble",
      "Este site utiliza cookies. Se você não alterar suas configurações, nós entendemos que você concorda com o uso de cookies.",
      "Baixar o app",
      "Baixar para Android",
      "Baixar para iOS",
      "Página não encontrada.",
      "Entrar",
      "Registrar",
      "Junte-se a nós!",
      "Ops, algo deu errado!"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "dias"
            ],
            "singular": [
                  "dia"
            ]
      },
      "hour": {
            "plural": [
                  "horas"
            ],
            "singular": [
                  "hora"
            ]
      },
      "minute": {
            "plural": [
                  "minutos"
            ],
            "singular": [
                  "minuto"
            ]
      },
      "month": {
            "plural": [
                  "meses"
            ],
            "singular": [
                  "mês"
            ]
      },
      "second": {
            "plural": [
                  "segundos"
            ],
            "singular": [
                  "segundo"
            ]
      },
      "year": {
            "plural": [
                  "anos"
            ],
            "singular": [
                  "ano"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
