/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Jobbigt att vara den som skickar först",
      "När någon tar bort en matchning",
      "Ghosting (och när det är ok)",
      "Jag är dejting-utbränd",
      "Tips på första dejten",
      "Mikroaggressioner och fetischisering",
      "Jag får inga matchningar",
      "Ingen skriver till mig först",
      "Förbereda sig för trygga IRL-möten",
      "Rapportera otrevliga meddelanden",
      "Upptäcka och återhämta sig från catfishing",
      "Jag är osäker på var jag ska börja",
      "Vad händer när jag rapporterar?",
      "Från Bumble till IRL",
      "Känslan att bli nobbad",
      "Skadligt beteende",
      "Mental utbrändhet",
      "Kärleksbedrägerier",
      "Ladda ner Bumble",
      "Acceptera alla",
      "Vi använder cookies för en bättre upplevelse. Detta inkluderar cookies för analys och för annonsering. För mer info, kolla in vår <a p0>Cookie-policy</a>.",
      "Se alternativ",
      "Ok med cookies?",
      "Cookiepolicy",
      "Gå tillbaka",
      "Felaktigt telefonnummer",
      "© {{current_year}} Bumble Alla rättigheter reserverade",
      "Sälj eller dela inte mina personliga uppgifter",
      "Karriär",
      "Vänskap",
      "Hur?",
      "Kärlek",
      "Skicka in din historia",
      "Hälsa",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Lediga jobb",
      "Företaget",
      "Events",
      "Det har aldrig funnits ett bättre tillfälle att träffa nya, intressanta personer.",
      "Följ Bumble",
      "Riktlinjer",
      "Hjälpcenter",
      "Hantera cookies",
      "Sekretesspolicy",
      "Appen",
      "Villkor och regler",
      "The Buzz",
      "Din säkerhet kommer alltid först.",
      "Läs vår säkerhetshandbok",
      "Bumble Trygghetscenter",
      "Guider",
      "Bumbles center för Säkerhet och välbefinnande är skapat för att du som medlem ska känna att du har de bästa förutsättningarna att dejta säkert och på bra villkor.",
      "Här håller du dig uppdaterad om trygghetsfunktioner som hjälper dig att dejta tryggt och på dina villkor. Du kan även komma i kontakt med oss här.",
      "Öppna Bumble-appen",
      "Välj Säkerhet och välbefinnande",
      "Tryck på profilikonen",
      "För att besöka avsnittet Säkerhet och välbefinnande",
      "Akut? Ring ditt lokala larmnummer.",
      "Behöver du hjälp?",
      "Behöver du hjälp?",
      "Ta en paus från Bumble när du än känner för det. Vi sparar dina matchningar.",
      "Snooze",
      "Var säker på att den du pratar med är äkta vara",
      "Fotoverifiering",
      "Om någon får dig att känna dig obekväm eller otrygg, rapportera det så kommer vi kika på det och ev. vidta åtgärder,",
      "Blockera och rapportera",
      "Om din matchning skickar ett oanständigt foto kommer det automatiskt att bli suddigt. Du väljer själv om du vill öppna det.",
      "Private Detector",
      "Vi har skapat en uppsättning säkerhetsfunktioner som utformats för att du ska känna dig trygg under din dejtingresa.",
      "Säkerhetsfunktioner",
      "Bumbles center för Säkerhet och välbefinnande är skapat för att du som medlem ska känna att du har de bästa förutsättningarna att dejta tryggt och på bra villkor.",
      "Din säkerhet kommer alltid först.",
      "Välkommen till Bumbles center för Säkerhet och välbefinnande",
      "Vi använder cookies. Om du inte är OK med det kan du göra ändringar i dina inställningar.",
      "Ladda ner appen",
      "Ladda ner för Android",
      "Ladda ner för iOS",
      "Sorry, vi hittar tyvärr inte sidan du letar efter.",
      "Logga in",
      "Gå med",
      "Bumbla med oss!",
      "Hoppsan, något gick fel..."
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "dagar",
                  "dagars"
            ],
            "singular": [
                  "dag",
                  "dags"
            ]
      },
      "hour": {
            "plural": [
                  "timmar",
                  "timmars"
            ],
            "singular": [
                  "timme",
                  "timmes"
            ]
      },
      "minute": {
            "plural": [
                  "minuter",
                  "minuters"
            ],
            "singular": [
                  "minut",
                  "minuts"
            ]
      },
      "month": {
            "plural": [
                  "månader",
                  "månaders"
            ],
            "singular": [
                  "månad",
                  "månads"
            ]
      },
      "second": {
            "plural": [
                  "sekunder",
                  "sekunders"
            ],
            "singular": [
                  "sekund",
                  "sekunds"
            ]
      },
      "year": {
            "plural": [
                  "år",
                  "års"
            ],
            "singular": [
                  "år",
                  "års"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
