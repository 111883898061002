/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Jeg er nervøs for at skrive først",
      "Blive slettet som match",
      "Ghosting (og hvornår det er ok)",
      "Jeg har dating-udbrændthed",
      "Tips til første date",
      "Mikroaggressioner og fetishisering",
      "Jeg får ingen matches",
      "Folk skriver ikke først til mig",
      "Sikkerhed, når i mødes",
      "Anmeld krænkende beskeder",
      "Afsløring & overvindelse af catfishing",
      "Hvor skal jeg starte?",
      "Det sker efter anmeldelse",
      "At mødes i person",
      "Følelsen af afvisning",
      "Skadelig opførsel",
      "Mental udmattelse",
      "Romantisk svindel",
      "Få Bumble",
      "Accepter alle",
      "Vi bruger Cookies til at forbedre funktionaliteten af vores side. Dette inkluderer analyse-Cookies og annoncerings-Cookies. For mere information, se venligst vores <a p0>Cookie-politik</a>.",
      "administrer valgmuligheder",
      "Fortæl os, om du tillader Cookies",
      "Cookiepolitik",
      "Tilbage",
      "Forkert telefonnummer",
      "© {{current_year}} Bumble | Alle rettigheder forbeholdes",
      "Sælg eller del ikke mine personlige informationer",
      "Karriere",
      "Venskab",
      "Sådan gør du",
      "Kærlighed",
      "Indsend en historie",
      "Helse/velvære",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Jobmuligheder",
      "Firmaet",
      "Begivenheder",
      "Der har aldrig været et bedre tidspunkt at møde nye, spændende mennesker på.",
      "Følg Bumble",
      "Retningslinjer",
      "Hjælpecenter",
      "Administrer cookies",
      "Privatlivspolitik",
      "Appen",
      "Vilkår & betingelser",
      "Blog",
      "Din sikkerhed kommer først, altid.",
      "Gennemse vores sikkerhedshåndbog",
      "Bumble Sikkerhedscenter",
      "Nyttige guides",
      "Centret for sikkerhed & velbefindende er et konstant udviklende resursecenter i Bumble-appen, skabt til at hjælpe vores fællesskab med at have en sikker og sund dating-oplevelse.",
      "Her kan du holde dig opdateret omkring sikkerhedsfunktioner, finde værktøjer og resurser, der hjælper dig med at date trygt og selvsikkert, samt have muligheden for direkte kommunikation med os.",
      "Åbn Bumble-appen",
      "Vælg sikkerhed & velbefindende",
      "Tryk på profil-ikonet",
      "For at besøge Centret for sikkerhed & velbefindende",
      "Hvis det er en nødssituation, så ring venligst til din lokale nødtelefon.",
      "Brug for en hånd?",
      "Brug for hjælp?",
      "Tag en pause fra Bumble, hvornår end, du har brug for det. Vi gemmer dine eksisterende matches.",
      "Snooze",
      "Vid, at den person, du taler med, er den, de siger, de er.",
      "Billedeverificering",
      "Hvis nogen gør dig utryg eller ukomfortabel til mode, så anmeld det og vi vil tage os af situationen.",
      "Bloker og anmeld",
      "Hvis dit match sender et eksplicit billede, så vil det automatisk blive sløret, og du vil kunne vælge, om du ønsker at se det eller ej.",
      "Private Detector",
      "Vi har bygget en sikkerheds-suite med funktioner, designet til at hjælpe dig med at være tryg igennem din dating-rejse.",
      "Sikkerhedsfunktioner",
      "Vi har bygget Centret for sikkerhed & velbefindende inde i Bumble-appen, som et resursecenter, der hjælper dig med at have en sikker og sund dating-oplevelse.",
      "Din sikkerhed kommer først, altid.",
      "Velkommen til Bumbles Center for sikkerhed & velbefindende",
      "Vi benytter cookies. Hvis du ikke redigere dine indstillinger, så antager vi, at du er okay med dette.",
      "Download appen",
      "Download til Android",
      "Download til iOS",
      "Beklager, men vi kan ikke finde den side, du ledte efter.",
      "Log ind",
      "Tilmeld",
      "Tilslut dig kuben!",
      "Hovsa, noget gik galt"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "dage",
                  "dages"
            ],
            "singular": [
                  "dag",
                  "dags"
            ]
      },
      "hour": {
            "plural": [
                  "timer",
                  "timers"
            ],
            "singular": [
                  "time",
                  "times"
            ]
      },
      "minute": {
            "plural": [
                  "minutter",
                  "minutters"
            ],
            "singular": [
                  "minut",
                  "minuts"
            ]
      },
      "month": {
            "plural": [
                  "måneder",
                  "måneders"
            ],
            "singular": [
                  "måned",
                  "måneds"
            ]
      },
      "second": {
            "plural": [
                  "sekunder",
                  "sekunders"
            ],
            "singular": [
                  "sekund",
                  "sekunds"
            ]
      },
      "year": {
            "plural": [
                  "år",
                  "årenes"
            ],
            "singular": [
                  "år",
                  "årets"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
