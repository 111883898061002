/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Nervios al iniciar una conversación",
      "Han eliminado el match conmigo",
      "Ghosting (y cuándo está bien)",
      "Me he cansado del dating",
      "Cómo gestionar las primeras citas",
      "Microagresiones y fetichismo",
      "No consigo hacer match",
      "La gente no inicia la conversación conmigo",
      "Conocerse en persona de forma segura",
      "Denunciar mensajes ofensivos",
      "Descubrir y recuperarse de un catfish",
      "No sé por dónde empezar",
      "¿Qué sucede cuando denuncias?",
      "De Bumble a la vida real",
      "Sentimiento de rechazo",
      "Comportamiento nocivo",
      "Agotamiento mental",
      "Estafas románticas",
      "Descargar Bumble",
      "Aceptarlas todas",
      "Utilizamos cookies para que nuestro sitio web funcione mejor. Estas incluyen cookies publicitarias y de análisis. Para más información, lee nuestra <a p0>Política de cookies</a>.",
      "Gestionar opciones",
      "Indícanos si aceptas las cookies",
      "Política de cookies",
      "Volver",
      "Número de teléfono incorrecto",
      "@{{current_year}}Bumble  | Todos los derechos reservados",
      "No vender ni compartir mis datos personales",
      "Profesional",
      "Amistad",
      "Consejos",
      "Amor",
      "Cuéntanos tu historia",
      "Bienestar",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Empleo",
      "Quiénes somos",
      "Eventos",
      "Nunca habrá mejor momento para conocer gente que nos ayude a ser mejores.",
      "Seguir Bumble",
      "Normas",
      "Centro de ayuda",
      "Gestionar cookies",
      "Política de privacidad",
      "La app",
      "Condiciones de uso",
      "Blog",
      "Tu seguridad siempre es lo primero.",
      "Consultar Manual de seguridad",
      "Centro de seguridad de Bumble",
      "Consejos",
      "El Centro de seguridad y bienestar es una fuente de recursos dentro de la app de Bumble, creado para ayudar a que nuestra comunidad tenga una experiencia sana y segura.",
      "Aquí podrás encontrar toda la información sobre nuestras medidas de seguridad, herramientas y recursos para darte confianza a la hora de conocer gente y una línea directa de comunicación con nosotros.",
      "Abre la app de Bumble",
      "Selecciona Seguridad y bienestar",
      "Pulsa sobre el icono del perfil",
      "Para visitar el Centro de seguridad y bienestar",
      "Si se trata de una emergencia, ponte en contacto con tu número de emergencias local.",
      "¿Podemos echarte una mano?",
      "¿Necesitas ayuda?",
      "Tómate un descanso de Bumble cuando lo necesites. Te guardaremos los matches que ya tengas para cuando vuelvas.",
      "No molestar",
      "Para que tengas la seguridad de que la persona con la que hablas es realmente quien dice ser.",
      "Verificación por foto",
      "Si alguien te hace sentir incomodidad o inseguridad, denúncialo y estudiaremos lo sucedido cuidadosamente.",
      "Bloquear y denunciar",
      "Si tu match envía una foto explícita, se difuminará automáticamente. Podrás decidir si quieres verla o no.",
      "Private Detector",
      "Hemos implementado varias medidas de seguridad diseñadas para protegerte en cada paso que des.",
      "Medidas de seguridad",
      "Hemos creado el Centro de seguridad y bienestar dentro de la app de Bumble como una fuente de recursos para ayudar a que nuestra comunidad tenga una experiencia sana y segura.",
      "Tu seguridad siempre es lo primero.",
      "Te damos la bienvenida al Centro de seguridad y bienestar de Bumble",
      "Este sitio web utiliza cookies. Al navegar por el sitio web sin modificar los ajustes, aceptas el uso de cookies por nuestra parte.",
      "Descargar la aplicación",
      "Descargar para Android",
      "Descargar para iOS",
      "Lo sentimos, pero no hemos podido encontrar la página que estabas buscando.",
      "Entrar",
      "Únete",
      "¡Únete!",
      "Vaya, se ha producido un error"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "días"
            ],
            "singular": [
                  "día"
            ]
      },
      "hour": {
            "plural": [
                  "horas"
            ],
            "singular": [
                  "hora"
            ]
      },
      "minute": {
            "plural": [
                  "minutos"
            ],
            "singular": [
                  "minuto"
            ]
      },
      "month": {
            "plural": [
                  "meses"
            ],
            "singular": [
                  "mes"
            ]
      },
      "second": {
            "plural": [
                  "segundos"
            ],
            "singular": [
                  "segundo"
            ]
      },
      "year": {
            "plural": [
                  "años"
            ],
            "singular": [
                  "año"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
